import { isTV } from 'app/device';
import { If } from 'helpers';
import { useSessionTracker } from 'hooks';
import { useEffect } from 'react';
import { SESSION_STATE } from 'slices';
import { NaclClient, WebClient } from './streaming-client';

export function GameSession() {
    const { sessionState } = useSessionTracker();

    useEffect(() => {
        // Hide all dom nodes when session is running
        if (sessionState.id === SESSION_STATE.RUNNING.id) {
            document.getElementById('root').classList.add('freeze');
        }
        return () => {
            document.getElementById('root').classList.remove('freeze');
        };
    }, [sessionState]);

    return (
        <If condition={sessionState.id === SESSION_STATE.RUNNING.id}>
            <If condition={isTV}>
                <NaclClient />
            </If>
            <If condition={!isTV}>
                <WebClient />
            </If>
        </If>
    );
}
