import { mobile } from 'app/device';
import { IconClose2 } from 'components/ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-self: flex-start;
    ${mobile(css`
        margin-left: 10px;
    `)}
    user-select: none;

    div:not(last-of-type) {
        margin-bottom: 16px;
    }
`;

export const IconClose = styled(IconClose2)`
    fill: rgba(255, 255, 255, 0.3);
`;
