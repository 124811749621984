import { AudioVideoSettings } from 'components/global-navigation';
import {
    ConfirmationBox,
    IconHeart,
    IconSearch,
    IconSettingsGlobal,
    LanguageBox,
} from 'components/ui';
import { SupportMessageBox } from 'components/ui/message-box/support-message-box/SupportMessageBox';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useAsideNav, useFavorites } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { globalNavigationSelector } from 'slices';
import * as S from './AsideNav.style';
import { Settings } from './settings/Settings';

export function AsideNav({ menuWrapperRef }) {
    const { t } = useTranslation();
    const asideNav = useAsideNav();
    const { games } = useFavorites();
    const { navigation, currentAsideTab } = useSelector(
        globalNavigationSelector
    );
    const { setFocus } = useSpatialNavContext();

    const buttonsArray = [];

    navigation.aside.forEach((asideItem, index) => {
        const commonProps = {
            key: index,
            onFocus: () =>
                asideNav.onAsideNavItemSelected(asideItem.name, index),
        };

        switch (asideItem.name) {
            case 'search':
                buttonsArray.push(
                    <S.SearchIcon
                        onBlur={asideNav.onSearchBlur}
                        overrideMoveDown={asideNav.overrideMoveDown}
                        isActive={currentAsideTab.name === 'search'}
                        {...commonProps}
                    >
                        <IconSearch />
                    </S.SearchIcon>
                );
                break;
            case 'favorites':
                buttonsArray.push(
                    <S.FavoriteIcon
                        ref={asideNav.favoritesIconRef}
                        checked={games.length > 0}
                        onBlur={asideNav.onFavoritesBlur}
                        isActive={currentAsideTab.name === 'favorites'}
                        overrideMoveDown={
                            games.length > 0 && asideNav.overrideMoveDown
                        }
                        {...commonProps}
                    >
                        <IconHeart />
                    </S.FavoriteIcon>
                );
                break;
            case 'settings':
                buttonsArray.push(
                    <S.SettingsIcon
                        ref={asideNav.settingsButtonRef}
                        onBlur={asideNav.onSettingsBlur}
                        isActive={currentAsideTab.name === 'settings'}
                        disableMoveRight
                        overrideMoveDown={asideNav.overrideMoveDown}
                        overrideMoveLeft={() => {
                            if (navigation.aside.length === 1) {
                                setFocus(menuWrapperRef.current);
                                return false;
                            } else {
                                return true;
                            }
                        }}
                        {...commonProps}
                    >
                        <IconSettingsGlobal />
                    </S.SettingsIcon>
                );
                break;
            default:
                if (process.env.NODE_ENV === 'development') {
                    console.error('Unkown side item type', asideItem.name);
                }
                break;
        }
    });

    return (
        <>
            <If condition={!asideNav.isEmpty}>
                {/*SHOW LOGOUT*/}
                <If condition={asideNav.logout.visible}>
                    <ConfirmationBox
                        message={t('login.logout_confirmation')}
                        decline={t('buttons.no')}
                        onAccept={asideNav.doLogout}
                        onDecline={() => {
                            asideNav.logout.callbacks.declineLogout();
                            asideNav.settingsButtonRef.current.focus();
                        }}
                    />
                </If>

                {/*SHOW AUDIO/VIDEO SETTINGS*/}
                <If condition={asideNav.audioVideoSettings.visible}>
                    <AudioVideoSettings
                        onClose={
                            asideNav.audioVideoSettings.hideAudioVideoSettings
                        }
                    />
                </If>

                {/*SHOW QUIT*/}
                <If condition={asideNav.quit.visible}>
                    <ConfirmationBox
                        message={t('settings.quit_confirmation')}
                        onAccept={asideNav.quit.callbacks.doQuit}
                        onDecline={() => {
                            asideNav.quit.callbacks.decline();
                            asideNav.settingsButtonRef.current.focus();
                        }}
                    />
                </If>

                {/*SHOW SUPPORT*/}
                <If condition={asideNav.support.visible}>
                    <SupportMessageBox
                        onClose={asideNav.support.hideSupport}
                        supportMethods={asideNav.support.supportMethods}
                    />
                </If>

                {/*SHOW LANGUAGE BOX*/}
                <If condition={asideNav.language.visible}>
                    <LanguageBox
                        lang={asideNav.language.lang}
                        changeLanguage={asideNav.language.changeLanguage}
                        onClose={asideNav.language.hideLanguageBox}
                    />
                </If>

                {/*SHOW SETTINGS*/}
                <If condition={asideNav.settingsVisible}>
                    <Settings
                        ref={asideNav.settingsBoxRef}
                        onLogout={asideNav.logout.callbacks.showLogoutBox}
                        onQuit={asideNav.quit.callbacks.show}
                        onShowSupport={asideNav.support.showSupport}
                        onShowLanguageBox={asideNav.language.showLanguageBox}
                        onShowAudioVideoSettings={
                            asideNav.audioVideoSettings.showAudioVideoSettings
                        }
                        lang={asideNav.language.lang}
                    />
                </If>

                <SpatialNavSection enterTo="last-focused">
                    <S.Wrapper ref={asideNav.wrapperRef}>
                        {buttonsArray}
                        <S.AsideNavSelector
                            numberOfMenuElements={buttonsArray.length}
                            ref={asideNav.asideNavCursorRef}
                        />
                    </S.Wrapper>
                </SpatialNavSection>
            </If>
        </>
    );
}
