import { mobile, mobileSmall, tv } from 'app/device';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    z-index: 900;
    margin-top: 10px;
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    user-select: none;
`;

export const Content = styled.div`
    width: 98vw;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SubContent = styled.div`
    width: 80vw;
    max-width: 1280px;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;

    ${tv(css`
        max-width: 90vw;
    `)}

    ${mobile(css`
        max-width: 90vw;
        min-width: 90vw;
    `)}

  ${mobileSmall(css`
        max-width: 98vw;
        width: auto;
    `)}
`;

export const MenuWrapper = styled.div`
    flex: 1;
    max-width: 1050px;
    ${mobile(css`
        max-width: 500px;
    `)}
    ${mobileSmall(css`
        max-width: 420px;
    `)}
`;

export const LogoWrapper = styled.div`
    width: 200px;
    align-self: stretch;

    ${mobile(css`
        max-width: 110px;
    `)}
    ${mobileSmall(css`
        max-width: 100px;
    `)};
`;

export const AsideNavWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;

    width: 200px;

    ${mobile(css`
        max-width: 110px;
    `)}

    ${mobileSmall(css`
        max-width: 100px;
    `)}
`;
