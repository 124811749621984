import 'api/client';
import {
    GlobalLoader,
    GlobalMessageBox,
    GlobalNavigation,
    GlobalPwaInstructions,
} from 'components';
import { AppProviders } from 'components/app-providers/AppProviders';
import { RouterWrapper } from 'components/router/RouterWrapper';
import { BuildVersion } from 'components/ui';
import 'i18n';
import 'normalize.css';
import { HospitalityAd } from './components/hospitality-ad/HospitalityAd';
import { Preloader } from './components/preloader/Preloader';

export default function App() {
    return (
        <AppProviders>
            <BuildVersion />

            {/* Global Loader (spinner) */}
            <GlobalLoader />

            {/* Wrap elements that depend on preloader's elements (maintenance, configFront)  */}
            <Preloader>
                <GlobalPwaInstructions debug={false} />
                <HospitalityAd debug={false} />
                <GlobalMessageBox />
                <GlobalNavigation />
                <RouterWrapper />
            </Preloader>
        </AppProviders>
    );
}
