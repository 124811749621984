import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import {
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { ItemList } from 'components/ui/swiper/ItemList';
import { If } from 'helpers';
import { useNewGamesNotification } from 'hooks';
import { useTranslation } from 'react-i18next';
import { NewGamesListPlain } from './NewGamesListPlain';
import { NewGamesListSwiper } from './NewGamesListSwiper';
import * as S from './NewGamesNotification.style';

export function NewGamesNotification() {
    const { onClose, gamesSwiper, newGames, visible } =
        useNewGamesNotification();

    const { t } = useTranslation();

    const openWindowWithGameList = (game) => (e) => {
        gamesSwiper.open(e, game);
    };

    return (
        <If condition={visible}>
            <If condition={gamesSwiper.visible}>
                <GameWindowSwiper
                    zIndex={902}
                    gamesSwiper={gamesSwiper}
                    selectedIndex={gamesSwiper.selectedIndex}
                    prev={gamesSwiper.prev}
                    next={gamesSwiper.next}
                    games={newGames}
                />
            </If>
            <MessageBoxWrapper zIndex={901}>
                <If condition={!gamesSwiper.visible}>
                    <S.ContentWrapper>
                        <MessageBoxTitle
                            alignItems="center"
                            title={t('games.newly.header')}
                            title2={t('games.newly.message')}
                        />
                        <S.Text>{t('games.newly.sub_message')}</S.Text>
                        <ItemList
                            list={newGames}
                            listItemSelected={openWindowWithGameList}
                            ListPlainComp={NewGamesListPlain}
                            ListSwiperComp={NewGamesListSwiper}
                        />
                        {/* TODO: Check if need to checkbox "don't show again" */}
                        <PrimaryButton onClick={onClose}>
                            {t('buttons.close')}
                        </PrimaryButton>
                    </S.ContentWrapper>
                </If>
            </MessageBoxWrapper>
        </If>
    );
}
