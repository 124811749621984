import { isMobileOrTablet, isTV } from 'app/device';
import {
    IconSettings,
    Link,
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If, qrCode } from 'helpers';
import { useBackPress } from 'hooks';
import { useSupportInfo } from 'hooks/support/useSupportInfo';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ActionButtonsWrapper } from '..';
import * as S from './SupportMessageBox.style';

export const SupportMessageBox = withInputDispatcherProvider(
    ({ onClose, supportMethods }) => {
        const { t } = useTranslation();
        useBackPress(onClose);
        const ref = useRef(null);

        useEffect(() => {
            if (!supportMethods) return;

            supportMethods.forEach((el) => {
                if (el.url) {
                    qrCode.append(ref.current);
                    qrCode.update({ data: el['url'] });
                }
            });
        }, [supportMethods]);

        const message = t('support.message');

        const device = useSupportInfo();

        const box = (
            <MessageBoxWrapper>
                <MessageBoxTitle
                    title={t('support.title')}
                    Icon={IconSettings}
                />
                <S.BuildVersion>
                    {t('support.version')} {process.env.REACT_APP_VERSION}
                    {' | '}
                    <If condition={device}>
                        {device.type} - {device.browser}
                    </If>
                    <If condition={isMobileOrTablet}> - {device.os}</If>
                </S.BuildVersion>
                <If condition={message}>
                    <Message>
                        {message}
                        {supportMethods.length > 0 ? <span>&nbsp;</span> : null}
                        {supportMethods.map((method, i) => {
                            const methodName = Object.keys(method)[0];
                            const methodValue = method[methodName];
                            return (
                                <React.Fragment key={i}>
                                    <If condition={methodName !== 'url'}>
                                        <S.SupportLink
                                            href={
                                                methodName === 'email'
                                                    ? `mailto:${methodValue}`
                                                    : `${methodValue}`
                                            }
                                            target="_blank"
                                        >
                                            {methodValue}
                                        </S.SupportLink>
                                    </If>
                                    <If condition={methodName === 'url'}>
                                        <Link
                                            defaultElement
                                            href={methodValue}
                                            Component={S.SupportLink}
                                        />
                                    </If>
                                    {i < supportMethods.length - 1
                                        ? ' - '
                                        : null}
                                </React.Fragment>
                            );
                        })}
                        <If condition={isTV}>
                            <S.QrCodeWrapper>
                                <Message>{t('login.scan_qrcode')}</Message>
                                <S.QrCode ref={ref} />
                            </S.QrCodeWrapper>
                        </If>
                    </Message>
                </If>
                <ActionButtonsWrapper>
                    <PrimaryButton onClick={onClose}>
                        {t('buttons.close')}
                    </PrimaryButton>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>
        );
        return ReactDOM.createPortal(box, document.body);
    }
);
