import { LogoNav, Menu } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useGlobalNavigation } from 'hooks';
import { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentSubMenuItems } from 'slices';
import { AsideNav } from './aside-nav/AsideNav';
import * as S from './GlobalNavigation.style';

export function GlobalNavigation() {
    const {
        navigation,
        currentTab,
        currentAsideTab,
        menuVisible,
        onTabSelected,
        onSubTabSelected,
        gameWindowOpen,
        isNavigationInitialized,
    } = useGlobalNavigation();
    const menuWrapperRef = useRef();
    const { refocus, refreshTree, setFocus, shouldRefocus } =
        useSpatialNavContext();

    // const currentSubMenuItems = [];
    const currentSubMenuItems = useSelector(selectCurrentSubMenuItems);

    // force focus on navbar when visible and a focus reset is necessary
    useLayoutEffect(() => {
        if ((menuVisible && shouldRefocus()) || !gameWindowOpen) {
            // force recomputation of nav items geometry when they become visible
            // so that the first item for initial focus can be determined correctly
            refreshTree();
        }
    }, [refreshTree, shouldRefocus, menuVisible, gameWindowOpen]);

    useLayoutEffect(() => {
        if (menuVisible && shouldRefocus()) refocus(menuWrapperRef.current);
    }, [shouldRefocus, refocus, menuVisible]);

    return (
        <SpatialNavSection enterTo="last-focused">
            <S.Wrapper isVisible={menuVisible}>
                <If condition={isNavigationInitialized}>
                    <S.Content>
                        <S.LogoWrapper>
                            <LogoNav />
                        </S.LogoWrapper>
                        <SpatialNavSection focusOnMount enterTo="last-focused">
                            <S.MenuWrapper ref={menuWrapperRef}>
                                <Menu
                                    isMain={true}
                                    items={navigation.main}
                                    currentAsideTab={currentAsideTab}
                                    navigation={navigation}
                                    namespace="navigation"
                                    onItemSelected={onTabSelected}
                                />
                            </S.MenuWrapper>
                        </SpatialNavSection>
                        <S.AsideNavWrapper>
                            <AsideNav menuWrapperRef={menuWrapperRef} />
                        </S.AsideNavWrapper>
                    </S.Content>
                    <If condition={currentSubMenuItems}>
                        <SpatialNavSection enterTo="last-focused">
                            <S.SubContent visible={!gameWindowOpen}>
                                <Menu
                                    items={currentSubMenuItems}
                                    currentAsideTab={currentAsideTab}
                                    namespace={currentTab.name}
                                    onItemSelected={onSubTabSelected}
                                    preventMoveRight={true}
                                    overrideMoveUp={() =>
                                        setFocus(menuWrapperRef.current)
                                    }
                                />
                            </S.SubContent>
                        </SpatialNavSection>
                    </If>
                </If>
            </S.Wrapper>
        </SpatialNavSection>
    );
}
