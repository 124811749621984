export default function RegisterServiceWorker() {
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
        // Use the window load event to keep the page load performant
        window.addEventListener('load', () => {
            navigator.serviceWorker.register(
                `${process.env.PUBLIC_URL}/service-worker.js`
            );
        });
    }
}
