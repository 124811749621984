import { isMobileOrTablet, isTV } from 'app/device';
import { IconClose2 } from 'components/ui';
import { If } from 'helpers';
import { useDisableAtmosSound, useWebClient } from 'hooks';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { gameSessionSelector, resetGameSession } from 'slices';
import * as S from './WebClient.style';

export function WebClient(props) {
    const containerRef = useWebClient(props);
    const dispatch = useDispatch();
    const { SGXConfig } = useSelector(gameSessionSelector);

    useDisableAtmosSound();

    const onClose = () => {
        dispatch(resetGameSession());
    };

    return ReactDOM.createPortal(
        <S.Wrapper>
            <If
                condition={
                    isMobileOrTablet ||
                    (!isTV &&
                        SGXConfig.options['client-type'] ===
                            'AndroidCloudGaming')
                }
            >
                <S.CloseButton>
                    <IconClose2 fill="white" onClick={onClose} />
                </S.CloseButton>
            </If>
            <S.Container ref={containerRef} />
        </S.Wrapper>,
        document.body
    );
}
