import { isMobile, isTV } from 'app/device';
import Theme from 'app/theme';
import { IconArrowLeft, IconArrowRight } from 'components/ui';
import { NavButton } from 'components/ui/buttons';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useSoundAction } from 'hooks';
import { forwardRef, useEffect, useRef } from 'react';
import * as S from './NavButtons.style';

export const descriptionNavBtn = {
    PREV: 'prev',
    NEXT: 'next',
};

export const NavButtons = forwardRef(
    ({ onClose, onNext, onPrev, index, indexMax }, forwardedRef) => {
        const { setEnabled, setFocus } = useSpatialNavContext();
        const prevRef = useRef();
        const nextRef = useRef();

        const prevDisabled = index === 0;
        const nextDisabled = index === indexMax;

        useEffect(() => {
            setEnabled(prevRef.current, !prevDisabled);
            setEnabled(nextRef.current, !nextDisabled);
        }, [nextDisabled, prevDisabled, setEnabled, setFocus]);

        const handleOnNext = useSoundAction('next', onNext);
        const handleOnPrev = useSoundAction('next', onPrev);

        return (
            <SpatialNavSection ref={forwardedRef} enterTo="default-element">
                <S.Wrapper>
                    <If condition={!isTV}>
                        <NavButton
                            defaultElement={nextDisabled && prevDisabled}
                            onClick={onClose}
                            fill={Theme.COLORS.WHITE}
                            opacity={0.35}
                            disableMoveRight={true} // do not move out of window (to settings)
                            disableMoveUp={true} // do not move out of window (to menu bar)
                        >
                            <S.IconClose />
                        </NavButton>
                    </If>
                    <NavButton
                        ref={prevRef}
                        defaultElement={nextDisabled && !prevDisabled}
                        onClick={handleOnPrev}
                        data-btn={descriptionNavBtn.PREV}
                        disableMoveRight={true} // do not move out of window (to settings)
                        disableMoveUp={isTV} // do not move out of window (to menu bar)
                        disableMoveDown={isMobile && nextDisabled} // do not move to action buttons (on mobile)
                        fill={Theme.COLORS.WHITE}
                        style={
                            prevDisabled && {
                                opacity: '0.35',
                                filter: 'saturate(0)',
                                pointerEvents: 'none',
                            }
                        }
                    >
                        <IconArrowLeft fill="white" />
                    </NavButton>
                    <NavButton
                        ref={nextRef}
                        defaultElement={!nextDisabled}
                        onClick={handleOnNext}
                        data-btn={descriptionNavBtn.NEXT}
                        disableMoveRight={true} // do not move out of window (to settings)
                        disableMoveDown={isMobile} // do not move to action buttons (on mobile)
                        fill={Theme.COLORS.WHITE}
                        style={
                            nextDisabled && {
                                opacity: '0.35',
                                filter: 'saturate(0)',
                                pointerEvents: 'none',
                            }
                        }
                    >
                        <IconArrowRight fill="white" />
                    </NavButton>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
