import { QuitWrapper, Scrollable } from 'components';
import {
    GamesWrapper,
    GameWindowMinimized,
    Grid,
    LeadingGame,
    NoGamesFound,
} from 'components/games';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout } from 'components/ui';
import { GlassTitle } from 'components/ui/glass-title/GlassTitle';
import {
    BigTitleWrapper,
    GlassTitleWrapper,
} from 'components/ui/glass-title/GlassTitle.style';
import { If } from 'helpers';
import { useFavorites, useGamesSwiper } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function FavoritesScreen() {
    const { games, mainFavorite, ...favorites } = useFavorites();
    const gamesSwiper = useGamesSwiper(games);
    const { t } = useTranslation();
    const glassTitleWrapperRef = useRef();
    const upperElementRef = useRef();
    const gridRef = useRef();

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={games.length > 0 && gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('favorites.no_games')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <LeadingGame>
                                <BigTitleWrapper>
                                    <GlassTitleWrapper
                                        ref={glassTitleWrapperRef}
                                        isVisible={!gamesSwiper.visible}
                                    >
                                        <GlassTitle
                                            wrapperRef={glassTitleWrapperRef}
                                        >
                                            {t('favorites.title')}
                                        </GlassTitle>
                                    </GlassTitleWrapper>
                                    <Scrollable
                                        isGapOpen={gamesSwiper.gapOpen}
                                        lastFocused={gamesSwiper.lastFocused}
                                    >
                                        <GameWindowMinimized
                                            game={mainFavorite}
                                            onUnFavorite={
                                                favorites.onUnFavorite
                                            }
                                            onExpandClicked={gamesSwiper.open}
                                            onQuickMatch={
                                                gamesSwiper.quickMatch
                                            }
                                            ref={upperElementRef}
                                            gridRef={gridRef}
                                        />
                                    </Scrollable>
                                </BigTitleWrapper>
                            </LeadingGame>
                            <Grid
                                ref={gridRef}
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={gamesSwiper.open}
                                columns={favorites.columns}
                                upperElementRef={upperElementRef}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
