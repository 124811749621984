import styled from 'styled-components';
import { maxCols } from 'helpers';

export const Column = styled.div`
    flex: 1;
    margin: 1vw;
    max-width: calc(
        100vw / ${maxCols}
    ); // divided by must match max col to display

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    & > * {
        margin-bottom: 2vw;
    }
`;
