import { maxCols } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Games } from 'services';
import { globalNavigationSelector } from 'slices';

export function useCategories() {
    const { currentTab } = useSelector(globalNavigationSelector);
    const [nbPlayers, setNbPlayers] = useState(2);
    const allGames = Games.GetGames(); // order may change when language is changed

    // Init columns
    const [columns, games] = useMemo(() => {
        const columns = [...Array(maxCols)].map(() => []);

        // get list of games - multi is filtered by number of players
        const games =
            currentTab.subTab === 'all'
                ? allGames
                : Games.GetCategoryGames(currentTab.subTab).filter(
                      (game) =>
                          currentTab.subTab !== 'multiplayer' ||
                          game.nb_players_local_multi >= nbPlayers ||
                          game.nb_players_online_multi >= nbPlayers
                  );
        // fill columns with filtered games
        games
            .filter((game) => game.release_status !== 'coming_soon')
            .forEach((game, index) => {
                columns[index % maxCols].push(game);
            });

        return [columns, games];
    }, [allGames, currentTab.subTab, nbPlayers]);

    const availableMultiNbPlayers = useMemo(() => {
        const games = Games.GetCategoryGames('multiplayer');
        const maxNbPlayers = Math.max(
            ...games.map((g) => g.nb_players_local_multi || 0)
        );
        if (maxNbPlayers < 2) {
            // may happen if no multi games, e.g. multiplayer feature disabled in backend
            // the category will be hidden but the code is still executed
            return [];
        }
        return [...Array(maxNbPlayers - 1)].map((_, i) => 2 + i);
    }, []);

    // reset number of players to 4 when changing tab
    useEffect(() => {
        setNbPlayers(2);
    }, [currentTab]);

    return {
        currentTab,
        columns,
        games,
        setNbPlayers,
        availableMultiNbPlayers,
    };
}
