import { hasTouch, isTV } from 'app/device';
import { getNumberOfGamepads, useInputDispatcherContext } from 'context';
import { useShowError } from 'hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    doLoadSGXConfig,
    doStartSession,
    gameSessionSelector,
    pinCodeCheckSelector,
    profileSelector,
    resetGameSession,
    SESSION_STATE,
    videoSettingsSelector,
} from 'slices';

export function useStartSession({ game, onSessionTerminated }) {
    const dispatch = useDispatch();
    const { sessionData, sessionState, sessionError } =
        useSelector(gameSessionSelector);
    const { resolution: settingsResolution } = useSelector(
        videoSettingsSelector
    );
    const { currentProfile } = useSelector(profileSelector);
    const { pinCode } = useSelector(pinCodeCheckSelector);
    const hasSession = useRef(false);
    // backdoor for changing in production: access main URL with ?resolution=1080p
    const search = new URLSearchParams(window.location?.search);
    const overrideResolution = search.get('resolution');
    const resolution = overrideResolution || settingsResolution;
    const platform = isTV ? 'standard' : 'js';
    const { stopInputDispatch, resumeInputDispatch } =
        useInputDispatcherContext();

    const { dispatchErrorMessageBox } = useShowError();
    const { i18n } = useTranslation();

    const resumeInput = useCallback(() => {
        // Resume input dispatch and mouse input
        resumeInputDispatch();
        document.getElementById('root').classList.remove('pauseMouseInput');
    }, [resumeInputDispatch]);

    // Clean game session state on unmount
    useEffect(() => {
        return () => {
            dispatch(resetGameSession());
        };
    }, [dispatch]);

    // Monitor session start errors
    useEffect(() => {
        if (hasSession.current && sessionError) {
            resumeInput();
            // FIXME: some errors have the error field, some are direct
            dispatchErrorMessageBox(sessionError.error || sessionError);
            dispatch(resetGameSession());
        }
    }, [dispatch, dispatchErrorMessageBox, resumeInput, sessionError]);

    // Monitor session state
    useEffect(() => {
        if (
            hasSession.current &&
            sessionState.id === SESSION_STATE.INITIAL.id
        ) {
            hasSession.current = false;
            onSessionTerminated && onSessionTerminated();
            resumeInput();
        }
    }, [onSessionTerminated, resumeInput, sessionState]);

    // Monitor session data
    useEffect(() => {
        if (hasSession.current && sessionData) {
            dispatch(
                doLoadSGXConfig({
                    sessionId: sessionData.sessionId,
                })
            );
        }
    }, [dispatch, sessionData]);

    // Start session
    const startSession = (multiplayer) => {
        // Pause input dispatch and mouse input when streaming client is running
        stopInputDispatch();
        document.getElementById('root').classList.add('pauseMouseInput');
        hasSession.current = true;

        dispatch(
            doStartSession({
                profileUID: currentProfile.uid,
                gameAlias: game.alias,
                language: i18n.language,
                pinCode: pinCode,
                useGamepad: getNumberOfGamepads() > 0,
                hasTouch: hasTouch(),
                resolution: resolution,
                platform: platform,
                multiplayer,
            })
        );
    };

    return {
        startSession,
        sessionState,
        sessionData,
        sessionError,
        hasSession,
    };
}
