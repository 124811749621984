import { isTV } from 'app/device';
import entryWallpaper from 'assets/images/wallpapers/entry.jpg';
// for some reasons, needs a minimal bg to transition from
import initialBg from 'assets/images/wallpapers/initial_bg.jpg';
import loginWallpaper from 'assets/images/wallpapers/login.jpg';
import wallpaper1 from 'assets/images/wallpapers/wallpaper_01.jpg';
import wallpaper2 from 'assets/images/wallpapers/wallpaper_02.jpg';
import wallpaper3 from 'assets/images/wallpapers/wallpaper_03.jpg';
import wallpaper4 from 'assets/images/wallpapers/wallpaper_04.jpg';
import wallpaper5 from 'assets/images/wallpapers/wallpaper_05.jpg';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { gameSessionSelector, SESSION_STATE } from 'slices';

const wallpapers = [wallpaper1, wallpaper2, wallpaper3, wallpaper4, wallpaper5];
const wallpaperInterval = 2 * 60 * 1000;

const staticWallpapers = {
    login: loginWallpaper,
    entry: entryWallpaper,
};

export function useWallpaper() {
    const [wallpaper, setWallpaper] = useState(initialBg);
    const wallpaperRef = useRef();
    const intervalRef = useRef();
    const timeoutRef = useRef();
    const { sessionState } = useSelector(gameSessionSelector);

    const startWallpaperLoop = useCallback(() => {
        // Disable wallpaper cycling on TV
        if (isTV) return;

        const doSetWallpaper = (p) => {
            wallpaperRef.current = p;
            setWallpaper(p);
        };

        // Delay wallpaper change
        timeoutRef.current = setTimeout(() => {
            doSetWallpaper(
                wallpapers[Math.floor(Math.random() * wallpapers.length)]
            );
        }, 1000);

        // set random wallpaper
        intervalRef.current = setInterval(() => {
            // Do nothing when we have a running session
            if (sessionState.id === SESSION_STATE.INITIAL.id) {
                // ensure we select a different wallpaper
                let nextWallpaper = undefined;
                do {
                    nextWallpaper =
                        wallpapers[
                            Math.floor(Math.random() * wallpapers.length)
                        ];
                } while (
                    wallpaperRef.current === nextWallpaper &&
                    wallpapers.length >= 2
                );
                doSetWallpaper(nextWallpaper);
            }
        }, wallpaperInterval);
    }, [sessionState.id]);

    const setStaticWallpaper = useCallback((paper) => {
        // stop loop
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setWallpaper(staticWallpapers[paper] || loginWallpaper);
    }, []);

    return {
        wallpaper,
        setWallpaper,
        setStaticWallpaper,
        startWallpaperLoop,
    };
}
