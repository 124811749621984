import Theme from 'app/theme';
import { IconLocked } from 'components/ui';
import { avatarURL, If } from 'helpers';
import { forwardRef } from 'react';
import * as S from './Profile.style';

export const Profile = forwardRef(
    ({ data, count, onClick, ...props }, forwardedRef) => {
        return (
            <S.SwiperSlideWrapper onClick={onClick}>
                <S.Wrapper>
                    <S.ProfileAvatarWrapper>
                        <S.ProfileAvatar
                            ref={forwardedRef}
                            src={avatarURL(data.avatar.file)}
                            count={count}
                            {...props}
                        />
                        <If condition={data.locked}>
                            <S.IconLockedWrapper>
                                <IconLocked fill={Theme.COLORS.WHITE} />
                            </S.IconLockedWrapper>
                        </If>
                    </S.ProfileAvatarWrapper>
                    <S.Tag>
                        <S.Text>{data.profileName}</S.Text>
                    </S.Tag>
                </S.Wrapper>
            </S.SwiperSlideWrapper>
        );
    }
);
