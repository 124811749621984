import { Endpoints, PrivateClient as client } from 'api';
import { defaultErrorHandler, doRequest, StatusCodes } from 'helpers';

import { osName, osVersion, isTV } from 'app/device';
import Games from './Games';

const osToBrand = {
    iOS: 'Apple',
    'Mac OS': 'Apple',
};

class GameSession {
    StartSession(params) {
        // increment profile stats
        Games.incrementProfileStats(params.gameAlias);

        const extraInfo = {
            os: osName,
            'os-version': osVersion,
            client: isTV ? 'mew-TV' : 'mew-web',
            'client-version': process.env.REACT_APP_VERSION,
            architecture: '',
            brand: osToBrand[osName] || osName,
        };

        let data = {
            profile: params.profileUID,
            alias: params.gameAlias,
            language: params.language,
            gamepad: params.useGamepad,
            touchscreen: params.hasTouch,
            pinCodeCheck: params.pinCode,
            // fps and bitrate are determined by the backend
            resolution: params.resolution,
            platform: params.platform,
            'screen-width': window.screen.width,
            'screen-height': window.screen.height,
            ...extraInfo,
        };

        let endPoint = Endpoints.START_SESSION;

        // MultiPlayer
        if (params.multiplayer?.isMultiplayer) {
            data['multiplayer-mode'] = 'quickmatch';
            if (params.multiplayer.owner) {
                data['lobby-structure'] = JSON.stringify(
                    params.multiplayer.lobbyStructure
                );
            } else {
                endPoint = Endpoints.JOIN_SESSION;
                data = {
                    profile: params.profileUID,
                    alias: params.gameAlias,
                    language: 'en',
                    gamepad: true,
                    touchscreen: params.hasTouch,
                    'screen-width': window.screen.width,
                    'screen-height': window.screen.height,
                    'multiplayer-mode': 'quickmatch',
                    masterSessionKey: params.multiplayer.masterSessionKey,
                    platform: params.platform,
                    ...extraInfo,
                };
            }
        }

        return doRequest({
            request: client.post(endPoint, data),

            [StatusCodes.OK]: ({ data }) => data,

            default: defaultErrorHandler,
        });
    }

    LoadSGXConfig({ sessionId }) {
        return doRequest({
            request: client.get(`${Endpoints.SGX_FILES}/${sessionId}.sgx`),

            [StatusCodes.OK]: ({ data }) => {
                const options = data;

                // Set command line for tizen NACL client
                let cmdLineConfig =
                    options['ip'] +
                    '  ' +
                    options['width'] +
                    ' ' +
                    options['height'] +
                    ' ' +
                    options['bitrate'] +
                    ' ' +
                    options['video-port'] +
                    ' ' +
                    options['input-port'] +
                    ' ' +
                    options['audio-port'] +
                    ' 0' +
                    ' 30' + // fps
                    ' ' +
                    options['key'];

                return {
                    cmdLine: cmdLineConfig,
                    options: options,
                };
            },
        });
    }
}

export default new GameSession();
