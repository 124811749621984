import Routes from 'app/routes';
import { useMultiStepContext } from 'context/multi-step';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showGlobalMessageBox } from 'slices';
import { doResetPassword, recoverySelector, resetSteps } from 'slices/recovery';

export function useResetPassword() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { cancelStep } = useMultiStepContext();
    const { step4Done, username, code } = useSelector(recoverySelector);
    const { register, handleSubmit, watch, errors } = useForm();
    const passwordRef = useRef({});
    passwordRef.current = watch('password', '');

    useEffect(() => {
        if (step4Done) {
            dispatch(resetSteps({ cancelled: false }));
            dispatch(
                showGlobalMessageBox({
                    type: 'success',
                    message: t('recovery.password_changed'),
                    routeTo: Routes.LOGIN,
                })
            );
        }
    }, [dispatch, step4Done, t]);

    // Handle on submit
    const onSubmit = handleSubmit((data) => {
        dispatch(
            doResetPassword({
                username: username,
                code: code,
                new_password: data.password,
            })
        );
    });

    return { onSubmit, register, errors, passwordRef, cancelStep };
}
