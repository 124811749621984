import { defaultErrorHandler, doRequest, StatusCodes } from 'helpers';
import { Endpoints, PrivateClient as client } from 'api';
import Authentication from './Authentication';

class Profile {
    constructor(options) {
        this.options = options || {};
    }

    async Preload(preloadCallback) {
        return doRequest({
            request: client.get(Endpoints.PROFILES),
            [StatusCodes.OK]: (response) => {
                if (this.options.debug) {
                    console.groupCollapsed('PROFILE - ACCOUNT INFOS');
                    console.log(
                        'ACCOUNT STATUS :',
                        Authentication.GetUserStatus()
                    );
                }

                // Format initial profiles
                preloadCallback(
                    response.data.map((profileData, i) => {
                        const mappedProfile =
                            convertProfileFromBackend(profileData);
                        if (this.options.debug) {
                            console.log(`profile ${i + 1} :`);
                            console.log(mappedProfile);
                        }

                        return mappedProfile;
                    })
                );
                if (this.options.debug) console.groupEnd();
            },
        });
    }

    async LoadFullProfile(uid, pinCode) {
        const data = pinCode ? { params: { pinCode } } : undefined;
        return doRequest({
            request: client.get(`${Endpoints.PROFILES}/${uid}`, data),
            [StatusCodes.OK]: (response) => {
                return convertProfileFromBackend(response.data);
            },
        });
    }

    async CheckPinCode({ profileUID, pinCode }) {
        return doRequest({
            request: client.post(Endpoints.CHECK_PIN_CODE, {
                profileUid: profileUID,
                pinCode: pinCode,
            }),
            [StatusCodes.OK]: true,
            [StatusCodes.FORBIDDEN]: false,
            default: defaultErrorHandler,
        });
    }

    async CreateProfile({
        password,
        profileName,
        avatar,
        contentRating,
        locked,
        pinCode,
        parentalControl,
    }) {
        let data = {
            playerPassword: password,
            nickname: profileName,
            avatar: avatar.alias,
            maxContentRating: contentRating.alias,
            isParentalControlActivated: parentalControl?.enabled || false,
            parentalControl: convertParentalControlToBackend(parentalControl),
        };
        if (locked) data.pinCode = pinCode;
        return doRequest({
            request: client.post(Endpoints.PROFILES, data),
            [StatusCodes.OK]: ({ data }) => {
                return {
                    hasError: false,
                    realName: data.nickname, // Backend may change the profile name
                    uid: data.uid,
                };
            },
            [StatusCodes.UNAUTHORIZED]: defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
            [StatusCodes.CONFLICT]: defaultErrorHandler,
            [StatusCodes.FORBIDDEN]: {
                hasError: true,
                error: { message: 'error.try_again_later' },
            },
        });
    }

    async UpdateProfile({
        password,
        uid,
        avatar,
        profileName,
        contentRating,
        locked,
        pinCode,
        parentalControl,
        oldState,
    }) {
        let data = {};

        // Avatar
        if (avatar.alias !== oldState.avatar.alias) {
            data.avatar = avatar.alias;
        }

        // Profile name
        if (profileName !== oldState.profileName) {
            data.nickname = profileName;
        }

        // Content rating
        if (contentRating.alias !== oldState.contentRating.alias) {
            data.maxContentRating = contentRating.alias;
        }

        // Remove PinCode
        if (!locked && oldState.locked) {
            data.removePinCode = true;
        }

        // PinCode
        if (locked && pinCode.length) {
            data.pinCode = pinCode;
        }

        // parental control
        const pcString = convertParentalControlToBackend(parentalControl);
        const oldString = convertParentalControlToBackend(
            oldState.parentalControl
        );
        if (pcString !== oldString) {
            data.parentalControl = pcString;
        }

        if (parentalControl?.enabled !== oldState.parentalControl?.enabled) {
            data.isParentalControlActivated = parentalControl.enabled;
        }

        // return immediately if nothing to do
        if (Object.keys(data).length === 0) {
            return Promise.resolve(true);
        }

        // Set player password
        data.playerPassword = password;

        return doRequest({
            request: client.put(`${Endpoints.PROFILES}/${uid}`, data),
            [StatusCodes.OK]: ({ data }) => {
                return {
                    hasError: false,
                    realName: data.nickname, // Backend may change the profile name
                };
            },
            [StatusCodes.UNAUTHORIZED]: defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
            [StatusCodes.CONFLICT]: defaultErrorHandler,
            [StatusCodes.FORBIDDEN]: {
                hasError: true,
                error: { message: 'error.try_again_later' },
            },
        });
    }

    async DeleteProfile({ password, uid }) {
        const data = { playerPassword: password };
        return doRequest({
            request: client.delete(`${Endpoints.PROFILES}/${uid}`, {
                data: data,
            }),
            [StatusCodes.OK]: { hasError: false },
            [StatusCodes.UNAUTHORIZED]: defaultErrorHandler,
            [StatusCodes.NOT_ACCEPTABLE]: defaultErrorHandler,
        });
    }
}

const convertProfileFromBackend = (profileData) => ({
    profileName: profileData.nickname,
    uid: profileData.uid,
    locked: profileData.has_pin_code,
    avatar: {
        alias: profileData.avatar_alias,
        file: profileData.avatar_filename,
    },
    contentRating: {
        alias: profileData.content_rating,
    },
    favorites: profileData.favorite_games_alias,
    parentalControl: convertParentalControlFromBackend(profileData),
    remainingPlayTime:
        profileData.remaining_playtime < 0
            ? Infinity
            : profileData.remaining_playtime,
});

const convertParentalControlFromBackend = ({
    parental_control_activated,
    parental_control,
}) => {
    if (!parental_control) {
        // missing data - parental control was never edited
        return undefined;
    }
    const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const days = dayNames.map((name, i) => {
        const dayData = parental_control[name];
        const maxTime = parseInt(dayData?.maxTime) || 0; // in minutes
        const start = parseInt(dayData?.startHour.split(':')[0], 10) || 0;
        const endHour =
            parseInt(dayData?.endHour.split(':')[0], 10) || start + 1;
        const endMinutes = parseInt(dayData?.endHour.split(':')[1], 10) || 0;
        return {
            dayIndex: i,
            hoursPerDay: maxTime < 0 ? Infinity : Math.floor(maxTime / 60),
            range: {
                start,
                end: endHour + (endMinutes !== 0 ? 1 : 0), // 18:00 -> 18, not 19, 18:01 -> 19
            },
        };
    });

    // reconstruct global range from min/max of days
    const globalRange = {
        start: Math.min(...days.map((d) => d.range.start)),
        end: Math.max(...days.map((d) => d.range.end)),
    };

    return {
        enabled: parental_control_activated,
        days,
        globalRange,
    };
};

const convertParentalControlToBackend = (parentalControl) => {
    if (!parentalControl) {
        return undefined;
    }
    const { enabled, days } = parentalControl;
    const dayNames = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    const backendFormat = {};
    days.forEach(({ dayIndex, hoursPerDay, range: { start, end } }) => {
        backendFormat[dayNames[dayIndex]] = {
            startHour: enabled ? `${start}:00` : '00:00',
            endHour: enabled ? `${end - 1}:59` : '23:59',
            maxTime: `${
                enabled && hoursPerDay !== Infinity ? hoursPerDay * 60 : -1
            }`,
        };
    });
    return JSON.stringify(backendFormat);
};

export default new Profile({
    debug: process.env.NODE_ENV === 'development',
});
