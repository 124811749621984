import { isTV } from 'app/device';
import {
    Link,
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If, qrCode } from 'helpers';
import { useBackPress } from 'hooks';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ActionButtonsWrapper } from '..';
import * as S from './QrCodeBox.style';

export const QrCodeBox = withInputDispatcherProvider(
    ({ title, message, onClose, url }) => {
        const { t } = useTranslation();
        const ref = useRef(null);
        useBackPress(onClose);

        useEffect(() => {
            qrCode.append(ref.current);
            qrCode.update({ data: url });
        }, [url]);

        // Note: nested backdrop-filters does not work
        const box = (
            <MessageBoxWrapper>
                <MessageBoxTitle title={title} />
                <Message>{message}</Message>
                <Link defaultElement href={url} Component={S.RegisterUrl} />
                <If condition={isTV}>
                    <Message>{t('login.scan_qrcode')}</Message>
                    <S.QrCode ref={ref} />
                </If>
                <ActionButtonsWrapper>
                    <PrimaryButton onClick={onClose}>
                        {t('buttons.close')}
                    </PrimaryButton>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>
        );

        return ReactDOM.createPortal(box, document.body);
    }
);
