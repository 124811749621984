import { IconHeart, Tags } from 'components/ui';
import { assetURL, If } from 'helpers';
import { useGameItem } from 'hooks';
import React from 'react';
import { Games } from 'services';
import * as S from './GameItem.style';

export const GameItem = React.forwardRef(
    ({ isGrid, game, src, ...props }, ref) => {
        const { isFavorite, favIconRef, animateAndToggle } = useGameItem(game);

        const GameComponent = isGrid ? S.GameGridItem : S.GameItem;

        const favoriteItemProps = {
            key: `favicon-${game.alias}`,
            ref: favIconRef,
            checked: isFavorite,
            onClick: animateAndToggle,
        };

        return (
            <GameComponent
                ref={ref}
                {...props}
                srcImage={src ? assetURL(src) : ''}
                data-alias={game.alias}
            >
                <If condition={Games.FavoritesEnabled()}>
                    <If condition={isGrid}>
                        {/* FavoriteGridItem is not - and must not - be a focusable, so doesn't have disableMoveLeft */}
                        <S.FavoriteGridItem {...favoriteItemProps}>
                            <IconHeart />
                        </S.FavoriteGridItem>
                    </If>
                    <If condition={!isGrid}>
                        <S.FavoriteIcon
                            {...favoriteItemProps}
                            disableMoveLeft
                            disableMoveUp={isGrid} // do not move out of window (to menu bar)
                        >
                            <IconHeart />
                        </S.FavoriteIcon>
                    </If>
                    <If condition={isGrid}>
                        <S.TagsWrapper>
                            <Tags game={game} isGrid={isGrid} />
                        </S.TagsWrapper>
                    </If>
                </If>

                <If condition={!src}>
                    <S.GameItemTextFallback>
                        {game.assets.title}
                    </S.GameItemTextFallback>
                </If>
            </GameComponent>
        );
    }
);
