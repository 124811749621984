import { useCallback } from 'react';
import * as S from './Column.style';
import { GameItem } from 'components/games';
import { Scrollable } from 'components/scroll-area';
import { useSpatialNavContext } from 'context';

export function Column({
    games,
    onColumnClicked,
    onItemClicked,
    colIndex,
    isGapOpen,
    lastFocused,
    isFarRight,
    sliceCount,
    disableAnimation,
    upperElementRef,
    noDetail,
}) {
    // when there is an element above the grid (e.g. detail window, multiplayer filter), it may have
    // been scrolled above the nav bar, which would fool the spatial nav
    // we need to ensure that moving up from the top column item will always focus the detail window
    const { setFocus } = useSpatialNavContext();
    const focusDetailWindow = useCallback(() => {
        if (upperElementRef.current) {
            // detail window present, focus it and stop processing
            setFocus(upperElementRef.current);
            return false;
        }
        // no detail window, do not override
        return true;
    }, [upperElementRef, setFocus]);

    const overrideMoveUp = (index) => {
        return index === 0 && upperElementRef ? focusDetailWindow() : true;
    };

    return (
        <S.Column>
            {[...games].splice(0, sliceCount).map((game, index) => (
                <Scrollable
                    key={`scroll-${index + colIndex}`}
                    isGapOpen={isGapOpen}
                    lastFocused={lastFocused}
                >
                    <GameItem
                        focusOnMount={index === 0 && colIndex === 0 && noDetail}
                        isGrid={true}
                        key={game.alias}
                        odd={(index + colIndex) % 2}
                        src={
                            (index + colIndex) % 2
                                ? game.assets.thumb
                                : game.assets.thumb_vertical
                        }
                        colIndex={colIndex}
                        itemIndex={index}
                        onClick={(e) => {
                            // optional column handler
                            onColumnClicked?.(colIndex);
                            // item handler
                            onItemClicked(e, game.alias); // need event for !event.isTrusted launch trailer in reduced UI, check useSubscribe
                        }}
                        game={game}
                        animatable={true}
                        overrideMoveUp={() => overrideMoveUp(index)}
                        disableMoveRight={isFarRight}
                        disableAnimation={disableAnimation}
                    />
                </Scrollable>
            ))}
        </S.Column>
    );
}
