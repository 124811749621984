import { IconClose, Input, NeutralButton } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollable } from '../../../scroll-area';
import * as S from './SearchBox.style';

export const SearchBox = forwardRef(
    (
        {
            onSearch,
            currentCue,
            onMoveDown,
            onFocus,
            onBlur,
            searchInputRef,
            isGapOpen,
            lastFocused,
        },
        forwardedRef
    ) => {
        const { t } = useTranslation();
        const { setFocus } = useSpatialNavContext();
        const [cue, setCue] = useState(currentCue);
        const clearRef = useRef();
        const searchRef = useRef();

        const clearVisible = cue.length > 0;

        // cue may be reset from the outside (e.g. most searched cues)
        useEffect(() => {
            setCue(currentCue);
        }, [currentCue]);

        const onChange = (e) => {
            setCue(e.target.value);
        };

        const onInputKeyDown = (e) => {
            if (e.keyCode === 13) {
                onSearch(searchInputRef.current.value);
            }
        };

        return (
            <Scrollable isGapOpen={isGapOpen} lastFocused={lastFocused}>
                <SpatialNavSection>
                    <S.Wrapper ref={forwardedRef}>
                        <S.InputWrapper>
                            <Input
                                defaultElement
                                onFocus={onFocus}
                                onBlur={onBlur}
                                ref={searchInputRef}
                                onKeyDown={onInputKeyDown}
                                onChange={onChange}
                                overrideMoveRight={() => {
                                    setFocus(
                                        clearVisible
                                            ? clearRef.current
                                            : searchRef.current
                                    );
                                }}
                                overrideMoveDown={onMoveDown}
                                name="username"
                                placeholder={t('search.placeholder')}
                                value={cue}
                            />
                            <S.Clear
                                ref={clearRef}
                                visible={clearVisible}
                                overrideMoveLeft={() => {
                                    setFocus(searchInputRef.current);
                                }}
                                onClick={() => {
                                    setCue('');
                                    setFocus(searchInputRef.current);
                                }}
                            >
                                <IconClose />
                            </S.Clear>
                        </S.InputWrapper>
                        <S.ButtonWrapper>
                            <NeutralButton
                                ref={searchRef}
                                overrideMoveDown={onMoveDown}
                                overrideMoveLeft={() => {
                                    setFocus(
                                        clearVisible
                                            ? clearRef.current
                                            : searchInputRef.current
                                    );
                                }}
                                overrideMoveRight={() => false}
                                onClick={() =>
                                    onSearch(searchInputRef.current.value)
                                }
                            >
                                {t('buttons.search')}
                            </NeutralButton>
                        </S.ButtonWrapper>
                    </S.Wrapper>
                </SpatialNavSection>
            </Scrollable>
        );
    }
);
