import { encrypt } from 'helpers';
import { useGamesSwiper } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Games } from 'services';
import { profileSelector } from 'slices';

export function useNewGamesNotification() {
    const { currentProfile } = useSelector(profileSelector);
    const profileUID = currentProfile.uid;
    const availableGames = useMemo(
        () => Games.GetAvailableGamesAliases(profileUID),
        [profileUID]
    );
    const newGamesAliases = useMemo(() => {
        // some games may appear with 'up' status without necessarily going through the whole regular
        // release status flow
        // the only reliable way to detect "new" games is to list games with 'new' or 'up' status and
        // check that list against that of the previous session

        // aliases of available games for all profiles
        const prevAvailableGamesData =
            JSON.parse(localStorage.getItem('previous_available_games')) || {};

        // available games for this profile
        const prevAvailableGames =
            prevAvailableGamesData[encrypt(profileUID, 'profileUID')];

        // entirely new session: do not handle the whole catalog as "new"
        if (!prevAvailableGames) {
            return [];
        }

        // new games that need to be notified
        const newGamesAliases = availableGames.filter(
            (alias) => !prevAvailableGames.includes(alias)
        );

        return newGamesAliases;
    }, [availableGames, profileUID]);

    // games use highlights ordering
    const newGames = useMemo(
        () =>
            Games.GetHighLights().games.filter((game) =>
                newGamesAliases.includes(game.alias)
            ),
        [newGamesAliases]
    );

    const gamesSwiper = useGamesSwiper(newGames);
    const [swiperRef, setSwiperRef] = useState(null);
    const [visible, setVisible] = useState(false);

    // update storage when new games have been computed and show notification if necessary
    useEffect(() => {
        const prevAvailableGamesData =
            JSON.parse(localStorage.getItem('previous_available_games')) || {};

        // update stored list for current profile
        prevAvailableGamesData[encrypt(profileUID, 'profileUID')] =
            availableGames;
        localStorage.setItem(
            'previous_available_games',
            JSON.stringify(prevAvailableGamesData)
        );

        if (newGamesAliases.length === 0) {
            return;
        }

        setVisible(true);
    }, [availableGames, newGamesAliases.length, profileUID]);

    const slidePrev = useCallback(() => {
        if (!swiperRef) return;

        swiperRef.slidePrev();
    }, [swiperRef]);

    const slideNext = useCallback(() => {
        if (!swiperRef) return;

        swiperRef.slideNext();
    }, [swiperRef]);

    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    return {
        onClose,
        visible,
        swiperRef,
        newGames,
        slidePrev,
        slideNext,
        setSwiperRef,
        gamesSwiper,
    };
}
