import { Endpoints, PrivateClient as client } from 'api';
import { hasTouch, isHP } from 'app/device';
import { assetURL, doRequest, preloadImage, StatusCodes } from 'helpers';
import i18n from 'i18n';
import { ConfigFront } from 'services';
import FrontEndHelper from './FrontEndHelper';

const releaseStatusOrder = ['new', 'coming_soon', 'up', 'down'];

class Games {
    constructor(options) {
        this.options = options || {};
        this.games = [];
        this.aliasToGame = {};
        this.filteredGames = [];
        this.filteredHighlightsGames = [];
        this.allCategories = [];
        this.availableOffers = [];
        this.subscribableOffers = [];
        this.subscribableOfferAliasToGames = {};
    }

    isDiscoveryOffer() {
        // also known as ReducedUI
        // /!\ .every() return true on empty array
        return this.availableOffers.every((o) => o.is_discovery_offer);
    }

    FavoritesEnabled() {
        return !isHP && !this.isDiscoveryOffer();
    }

    SortGames() {
        // games are sorted alphabetically by localized title by default
        this.filteredGames = this.filteredGames
            .slice() // force reconstruction of array to trigger update in screens through hooks
            .sort((a, b) => a.assets.title?.localeCompare(b.assets.title));

        // highlights have a specific sorting
        this.filteredHighlightsGames = [...this.filteredGames].sort(
            this.HighlightsSort
        );

        // Lookup table to speed up things
        let categoryLookupTable = {};
        this.allCategories = [];
        const touchCateg = 'touch';

        // Index games and add them to categories
        this.filteredGames.forEach((game) => {
            if (game.is_touch_friendly) {
                game.category.push(touchCateg);
            }

            game.category.forEach((cat) => {
                let gameCat = cat.toLowerCase();
                if (!(gameCat in categoryLookupTable)) {
                    this.allCategories.push({
                        name: gameCat,
                        games: [],
                    });
                    categoryLookupTable[gameCat] =
                        this.allCategories.length - 1;
                }

                this.allCategories[categoryLookupTable[gameCat]].games.push(
                    game
                );
            });
        });

        // touch and multiplayer will be added at the end
        const touchCategory = this.allCategories.find(
            (c) => c.name === 'touch'
        );
        const multiplayerCategory = this.allCategories.find(
            (c) => c.name === 'multiplayer'
        );

        // sort categories by number of games, then name
        this.allCategories = this.allCategories
            .filter((c) => c.name !== 'touch' && c.name !== 'multiplayer')
            .sort(
                (a, b) =>
                    b.games.length - a.games.length ||
                    a.name.localeCompare(b.name)
            );
        if (touchCategory && hasTouch()) {
            this.allCategories.push(touchCategory);
        }
        // the multiplayer category may have been filtered out of games, see sanitizeMultiplayer
        if (multiplayerCategory) {
            this.allCategories.push(multiplayerCategory);
        }
    }

    Filter(minimumAge) {
        // Filter games by age
        this.filteredGames = [...this.games].filter(
            (game) =>
                minimumAge >= FrontEndHelper.GetMinimumAge(game.content_ratings)
        );

        this.SortGames();
    }

    HighlightsSort(a, b) {
        // Sort by priority first
        if (a.priority > b.priority) {
            return -1;
        }
        if (a.priority < b.priority) {
            return 1;
        }

        // then release status
        const statusA = releaseStatusOrder.indexOf(a.release_status);
        const statusB = releaseStatusOrder.indexOf(b.release_status);
        if (statusA !== -1 && statusA < statusB) {
            return -1;
        }
        if (statusB !== -1 && statusA > statusB) {
            return 1;
        }

        // then by localized title
        // this is initialized from assets and current locale, see doRequest
        return a.assets.title?.localeCompare(b.assets.title);
    }

    GetHighLights() {
        // the highlighted game is the first one after the initial sorting
        return {
            highlighted: this.filteredHighlightsGames?.[0],
            games: this.filteredHighlightsGames,
        };
    }

    GetGamesFromSubscribableOfferAlias(alias) {
        return this.subscribableOfferAliasToGames[alias];
    }

    GetGames() {
        return this.filteredGames;
    }

    GetAvailableGamesAliases() {
        // available games this session
        return this.GetGames()
            .filter(
                (game) =>
                    game.release_status === 'new' ||
                    game.release_status === 'up'
            )
            .map((game) => game.alias);
    }

    GetGameFromAlias(alias) {
        return this.aliasToGame[alias];
    }

    GetCategories() {
        return this.allCategories;
    }

    GetFilteredCategories() {
        // touch and multiplayer are always at the end if present
        const specialCategoriesNames = ['touch', 'multiplayer'];
        const specialCategories = this.allCategories.filter((c) =>
            specialCategoriesNames.includes(c.name)
        );

        const nbCategories = 6;
        return this.allCategories
            .slice(
                0,
                Math.min(nbCategories, this.allCategories.length) -
                    specialCategories.length
            )
            .concat(specialCategories);
    }

    GetCategoryGames(category) {
        const theCategory = this.allCategories.filter((cat) => {
            return cat.name.toLowerCase() === category.toLowerCase();
        });

        // may be undefined if no games in the requested category
        return theCategory[0]?.games || [];
    }

    GetAvailableOffers() {
        return this.availableOffers;
    }

    GetSubscribableOffers() {
        return this.subscribableOffers;
    }

    SetStats(profileUID) {
        const url = `${Endpoints.STATS}/${profileUID}`;
        return doRequest({
            request: client.get(url),
            [StatusCodes.OK]: ({ data }) => {
                // platform stats
                const gameStats = data.platform_stats;

                // regroup profile stats
                const profileStats = (data.profile_stats || []).reduce(
                    (stats, { alias }) => ({
                        ...stats,
                        [alias]: (stats[alias] || 0) + 1,
                    }),
                    {}
                );

                if (this.options.debug) {
                    console.groupCollapsed('Game stats');
                    const aliases = this.games.map((g) => g.alias);
                    console.groupCollapsed('Platform');
                    for (const alias in gameStats) {
                        if (aliases.includes(alias)) {
                            console.log(alias, gameStats[alias]);
                        }
                    }
                    console.groupEnd();
                    console.groupCollapsed('Profile');
                    for (const alias in profileStats) {
                        if (aliases.includes(alias)) {
                            console.log(alias, profileStats[alias]);
                        }
                    }
                    console.groupEnd();
                    console.groupEnd();
                }

                // set sessionCount for all games
                this.games.forEach((game) => {
                    game.sessionCount = gameStats[game.alias] || 0;
                    game.profileSessionCount = profileStats[game.alias] || 0;
                });
            },
            // ignore other errors
            default: (response) => {
                if (this.options.debug)
                    console.log(
                        'Unexpected response',
                        response.status,
                        `for ${url}`,
                        response
                    );
            },
        });
    }

    incrementProfileStats(alias) {
        const game = this.aliasToGame[alias];
        if (!game) {
            return;
        }
        game.profileSessionCount++;
    }

    // DEPRECATED
    async PreloadDeprecated(promises, approvedGamesCallback) {
        return doRequest({
            request: client.get(Endpoints.GAMES),

            [StatusCodes.OK]: (response) => {
                // Retrieve available packs
                const data = response.data;
                const allGames = data.games;
                const packs = data.packs;
                const packsAvailable = data.packs_available;

                let gamesAliasesInAvailablePacks = packs
                    .filter((pack) => packsAvailable.includes(pack.alias))
                    .map((pack) => pack.games_aliases);

                // Merging arrays retrieved for more practicality
                gamesAliasesInAvailablePacks = [].concat.apply(
                    [],
                    gamesAliasesInAvailablePacks
                );

                // Remove duplicate games (duplicates appear because multiple packs can contain same games)
                gamesAliasesInAvailablePacks = [
                    ...new Set(gamesAliasesInAvailablePacks),
                ];

                // Retrieve all games in available packs
                this.games = allGames.filter(
                    (game) =>
                        // Game status must be up or new
                        (game.release_status === 'up' ||
                            game.release_status === 'new') &&
                        gamesAliasesInAvailablePacks.includes(game.alias)
                );

                // Format games and push optional promises
                this.games.forEach((game) => {
                    this.aliasToGame[game.alias] = game;

                    // 0 until set though SetStats()
                    game.sessionCount = 0;

                    game.assets.cover =
                        game.assets?.[i18n.language]?.wallpaper ||
                        game.assets?.[this.options.fallbackLang]?.wallpaper;
                    game.assets.thumb =
                        game.assets?.[i18n.language]?.icon ||
                        game.assets?.[this.options.fallbackLang]?.icon;
                    game.assets.thumb_vertical =
                        game.assets?.[i18n.language]?.icon ||
                        game.assets?.[this.options.fallbackLang]?.icon;
                    game.assets.description =
                        game.assets?.[i18n.language]?.description ||
                        game.assets?.[this.options.fallbackLang]?.description;
                    game.assets.title =
                        game.assets?.[i18n.language]?.title ||
                        game.assets?.[this.options.fallbackLang]?.title ||
                        game.name;
                    game.assets.trailer =
                        game.assets?.[i18n.language]?.trailer ||
                        game.assets?.[this.options.fallbackLang]?.trailer;

                    // Preload thumb
                    if (game.assets.thumb)
                        promises.push(
                            preloadImage(assetURL(game.assets.thumb))
                        );

                    // Preload vertical thumb
                    if (game.assets.thumb_vertical)
                        promises.push(
                            preloadImage(assetURL(game.assets.thumb_vertical))
                        );

                    this.sanitizeMultiplayer(game);
                });

                // Approved Eula games
                approvedGamesCallback(
                    (Array.isArray(data.approved_eulas) &&
                        data.approved_eulas) ||
                        []
                );

                // Debug
                if (this.options.debug) {
                    console.log('Games aliases in available packs:');
                    console.log(gamesAliasesInAvailablePacks);

                    console.log('All available Games: ');
                    console.log(this.games);
                }
            },
        });
    }

    sanitizeMultiplayer(game) {
        // sanitize multiplayer information coming from the back, esp. for the QA backend
        // e.g. "multi" games with max 1 player, or multi mode entirely disabled
        if (!ConfigFront.GetMultiplayer() || game.nb_players_online_multi < 2) {
            game.nb_players_online_multi = 0;
        }
        if (game.nb_players_local_multi < 2) {
            game.nb_players_local_multi = 0;
        }
        if (
            game.nb_players_local_multi === 0 &&
            game.nb_players_online_multi === 0
        ) {
            game.category = game.category.filter((c) => c !== 'Multiplayer');
        }
        game.hasQuickMatch = game.nb_players_online_multi > 1;
    }

    // Update games assets according to the requested language and push optional promises
    UpdateAssets(lang, promises = []) {
        // get named asset with language fallback
        const getAsset = (item, asset) =>
            item.assets?.[lang]?.[asset] ||
            item.assets?.[this.options.fallbackLang]?.[asset];

        // get single value, or first item if array value
        const getSingleAsset = (item, asset) => {
            const a = getAsset(item, asset);
            return (Array.isArray(a) && a[0]) || a;
        };

        const formatGame = (game) => {
            // 0 until set though SetStats()
            game.sessionCount = 0;

            // icon_square & icon_vertical for grid display
            game.assets.thumb = getSingleAsset(game, 'icon_square');
            game.assets.thumb_vertical = getSingleAsset(game, 'icon_vertical');
            // square cover for detail game display
            game.assets.cover = getSingleAsset(game, 'cover_square');

            game.assets.description = getAsset(game, 'description');
            game.assets.title = getAsset(game, 'title') || game.name;
            game.assets.trailer = getSingleAsset(game, 'trailer');
        };

        const pushInPromises = (game) => {
            // Preload full cover
            if (game.assets.cover)
                promises.push(preloadImage(assetURL(game.assets.cover)));

            // Preload vertical thumb
            if (game.assets.thumb_vertical)
                promises.push(
                    preloadImage(assetURL(game.assets.thumb_vertical))
                );

            // Preload thumb
            if (game.assets.thumb)
                promises.push(preloadImage(assetURL(game.assets.thumb)));
        };

        // all games available in packs
        this.games.forEach((game) => {
            this.aliasToGame[game.alias] = game;

            formatGame(game);
            pushInPromises(game);

            this.sanitizeMultiplayer(game);
        });

        // all games in subcribable offers
        for (const offer in this.subscribableOfferAliasToGames) {
            this.subscribableOfferAliasToGames[offer].forEach((game) => {
                formatGame(game);
                this.aliasToGame[game.alias] = game;

                //promises
                if (this.games.includes(game)) return;
                pushInPromises(game);
            });
        }

        // update assets of available and subscribable offers
        [
            ...new Set([...this.availableOffers, ...this.subscribableOffers]),
        ].forEach((offer) => {
            offer.assets.title = getAsset(offer, 'title');
            offer.assets.short_description = getAsset(
                offer,
                'short_description'
            );
            offer.assets.description = getAsset(offer, 'description');
            offer.assets.buy_link = getAsset(offer, 'buy-link');
            offer.assets.packshot = getSingleAsset(offer, 'packshot');

            if (offer.assets.packshot)
                promises.push(preloadImage(assetURL(offer.assets.packshot)));
        });
    }

    // offers_available as {alias: string, endDate: string}[] format
    FixAvailableOffers(initialAvailableOffers) {
        // format of offers_available has changed between 1.6.3 and 1.6.4 versions of the
        // backend earlier versions provide an array of aliases, later versions provide an
        // array of objects with alias and endDate
        const newFormat = typeof initialAvailableOffers[0] === 'object';
        if (newFormat) {
            return initialAvailableOffers;
        }
        // convert to new format
        return initialAvailableOffers.map((alias) => ({
            alias,
            endDate: null,
        }));
    }

    async Preload(promises, approvedGamesCallback, noOffersCallback) {
        const backendVersion = ConfigFront.GetBackendVersion();
        if (
            backendVersion.major < 1 ||
            (backendVersion.major === 1 && backendVersion.minor < 6)
        ) {
            return this.PreloadDeprecated(promises, approvedGamesCallback);
        }

        return doRequest({
            request: client.get(Endpoints.GAMES),

            [StatusCodes.OK]: (response) => {
                // Retrieve available packs
                const data = response.data;
                const allGames = data.games;
                const offers = data.offers;
                this.allPacks = data.packs;

                /**
                 * OFFERS
                 */
                // active offers, reconstruct from alias, end Date and full offers
                this.availableOffers = this.FixAvailableOffers(
                    data.offers_available
                ).map(({ alias, endDate }) => ({
                    ...offers.find((fullOffer) => fullOffer.alias === alias),
                    endDate,
                }));

                noOffersCallback(this.availableOffers.length < 1);

                // offers with "can_be_subscribed : true"
                this.subscribableOffers = offers.filter(
                    (offer) => offer.can_be_subscribed
                );

                this.subscribableOffers.forEach((offer) => {
                    //retrieve pack aliases
                    const packAliases = offer.packs_aliases;

                    let packs = this.allPacks.filter((pack) =>
                        packAliases.includes(pack.alias)
                    );

                    // retrieve game aliases from pack
                    let game_aliases = packs.map((pack) => pack.games_aliases);
                    //flatten
                    //TODO : refacto
                    game_aliases = [].concat.apply([], game_aliases);
                    game_aliases = [...new Set(game_aliases)];

                    // retrieve game from aliases and sort by priority
                    const gamesFromSubscribableOfferAlias = allGames
                        .filter(
                            (game) =>
                                game.release_status !== 'down' &&
                                game_aliases.includes(game.alias)
                        )
                        .sort(this.HighlightsSort);

                    this.subscribableOfferAliasToGames[offer.alias] =
                        gamesFromSubscribableOfferAlias;
                });

                /**
                 * AVAILABLE GAMES
                 */

                let packsAliasesInAvailableOffers = offers
                    .filter((offer) =>
                        this.availableOffers.some(
                            (availableOffer) =>
                                availableOffer.alias === offer.alias
                        )
                    )
                    .map((availableOffer) => availableOffer.packs_aliases);

                // Merging arrays retrieved for more practicality
                packsAliasesInAvailableOffers = [].concat.apply(
                    [],
                    packsAliasesInAvailableOffers
                );

                // Remove duplicate packs (duplicates appear because multiple packs can contain same games)
                packsAliasesInAvailableOffers = [
                    ...new Set(packsAliasesInAvailableOffers),
                ];

                let packsInAvailableOffers = this.allPacks.filter((pack) =>
                    packsAliasesInAvailableOffers.includes(pack.alias)
                );

                let gamesAliasesInAvailableOffersPacks =
                    packsInAvailableOffers.map((pack) => pack.games_aliases);

                // Merging arrays retrieved for more practicality
                gamesAliasesInAvailableOffersPacks = [].concat.apply(
                    [],
                    gamesAliasesInAvailableOffersPacks
                );

                // Remove duplicate games (duplicates appear because multiple packs can contain same games)
                gamesAliasesInAvailableOffersPacks = [
                    ...new Set(gamesAliasesInAvailableOffersPacks),
                ];

                // initialize alias table, stats, quickmatch for all games
                allGames.forEach((game) => {
                    this.aliasToGame[game.alias] = game;

                    // 0 until set though SetStats()
                    game.sessionCount = 0;

                    this.sanitizeMultiplayer(game);
                });

                // Retrieve all games in available packs
                this.games = allGames.filter(
                    (game) =>
                        // Game status must be up or new or coming soon but not "down"
                        game.release_status !== 'down' &&
                        gamesAliasesInAvailableOffersPacks.includes(game.alias)
                );

                // initialize assets
                this.UpdateAssets(i18n.language, promises);

                // Approved Eula games
                approvedGamesCallback(
                    (Array.isArray(data.approved_eulas) &&
                        data.approved_eulas) ||
                        []
                );

                // Debug
                if (this.options.debug) {
                    console.groupCollapsed('GAMES Preload new API');

                    console.log('Response:');
                    console.log(response);

                    console.log('Available offers:');
                    console.log(this.availableOffers);

                    console.log('Subscribable offers');
                    console.log(this.subscribableOffers);

                    console.log('Offers:');
                    console.log(data.offers);

                    console.log('PacksInAvailableOffers:');
                    console.log(packsAliasesInAvailableOffers);

                    console.log('PacksInAvailableOffers:');
                    console.log(packsInAvailableOffers);

                    console.log('Available games:');
                    console.log(this.games);

                    console.groupEnd();
                }
            },
        });
    }
}

export default new Games({
    debug: process.env.NODE_ENV === 'development',
    fallbackLang: 'en',
});
