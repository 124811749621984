import { useAppSound, useScrollGap } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Games } from 'services';
import { showGameWindow, hideGameWindow } from 'slices';

export function useGamesSwiper(games) {
    const scrollGap = useScrollGap();
    const [visible, setVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedAlias, setSelectedAlias] = useState('');
    const dispatch = useDispatch();
    const lastFocused = useRef();
    const defaultView = useRef('');
    const currentGameWindowRef = useRef();
    const [playGameWindowOpen] = useAppSound('gameWindow');

    const open = useCallback(
        (e, alias) => {
            // e is unused, but this can be called by something expecting a generic callback that
            // *does* take an event as first argument
            // see use of GameItem in Column.js
            playGameWindowOpen();
            lastFocused.current = document.activeElement;
            // in some screens (charts, search), the game list may be changed just before the window
            // is opened so the index cannot be set directly, otherwise there would be a short time
            // with a mismatch (or no match) between the games list and the requested index
            // instead we set the selected alias, and the effect below will set the correct index
            // from the alias and games list once both are set, then make the window visible
            setSelectedAlias(alias);

            dispatch(showGameWindow());
            scrollGap.open();
        },
        [dispatch, scrollGap, playGameWindowOpen]
    );

    const quickMatch = useCallback(
        (alias) => {
            defaultView.current = 'multi';
            open(null, alias);
        },
        [open]
    );

    // computes the selected index from the set of games and selected alias
    // and shows the window
    useEffect(() => {
        if (games.length === 0) {
            setVisible(false);
            return;
        }

        const index = games.indexOf(Games.GetGameFromAlias(selectedAlias));
        setSelectedIndex(index);

        if (index >= 0) {
            setVisible(true);
        }
    }, [games, selectedAlias]);

    const close = () => {
        setVisible(false);
        scrollGap.close();
        setSelectedAlias(null);
        dispatch(hideGameWindow());
        defaultView.current = '';
    };

    // because the index is computed from the alias, it should not be set directly
    // from outside the hook. These two helpers correctly compute the previous and
    // next aliases from the current state and must be used instead to switch between games
    const next = useCallback(() => {
        setSelectedAlias((prevAlias) => {
            const index = games.indexOf(Games.GetGameFromAlias(prevAlias));
            if (index < games.length - 1) {
                return games[index + 1].alias;
            }
            return prevAlias;
        });
    }, [games]);

    const prev = useCallback(() => {
        setSelectedAlias((prevAlias) => {
            const index = games.indexOf(Games.GetGameFromAlias(prevAlias));
            if (index > 0) {
                return games[index - 1].alias;
            }
            return prevAlias;
        });
    }, [games]);

    return {
        visible,
        defaultView,
        open,
        close,
        quickMatch,
        next,
        prev,
        selectedIndex,
        lastFocused,
        gapOpen: scrollGap.isOpen,
        currentGameWindowRef,
    };
}
