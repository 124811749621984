import { mobile, tv } from 'app/device';
import LogoNavImage from 'assets/images/logo-nav.png';
import styled, { css } from 'styled-components';

export const LogoNav = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${LogoNavImage})`,
        ...props.style,
    },
}))`
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 50px;

    ${mobile(css`
        height: 30px;
    `)}

    ${tv(css`
        height: 58px;
    `)}
`;
