import { isMobileOrTablet, isTV } from 'app/device';
import { descriptionActionBtn } from 'components/games/game-window/views/description/DescriptionView';
import { descriptionNavBtn } from 'components/games/game-window/views/description/nav-buttons/NavButtons';
import { useSpatialNavContext } from 'context';
import { useTriggerBottomLeftPress, useTriggerBottomRightPress } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { GameWindow } from '../game-window/GameWindow';
import * as S from './GameWindowSwiper.style';

SwiperCore.use([Virtual]);

export const GameWindowSwiper = ({
    gamesSwiper,
    games,
    selectedIndex,
    prev,
    next,
    zIndex,
}) => {
    const initialIndex = useRef(selectedIndex).current; // stored once on mount
    const { refreshTree, setFocus } = useSpatialNavContext();
    const [swiperRef, setSwiperRef] = useState(null);
    const [swiperTransitionActive, setSwiperTransitionActive] = useState(false);
    const activeSlide = useRef();
    // each window inside the swiper has its own MultiView and matching context
    // the current window will call setCurrentGameWindowView so we can known the\
    // information from outside
    const [currentGameWindowView, setCurrentGameWindowView] = useState(
        gamesSwiper.defaultView.current
    );
    // this is used to restore focus on specific buttons on certain transitions
    // between views for a single game, or between games when swiping
    const [lastFocusedEl, setLastFocusedEl] = useState('');

    useEffect(() => {
        if (!swiperTransitionActive) refreshTree();
    }, [refreshTree, swiperTransitionActive]);

    const onNext = useCallback(
        (focus) => {
            if (swiperTransitionActive || !swiperRef) return;

            if (selectedIndex < games.length - 1) {
                next();
                swiperRef.slideNext();
                setLastFocusedEl(focus);
            }
        },
        [games.length, next, selectedIndex, swiperRef, swiperTransitionActive]
    );

    const onPrev = useCallback(
        (focus) => {
            if (swiperTransitionActive || !swiperRef) return;

            if (selectedIndex > 0) {
                prev();
                swiperRef.slidePrev();
                setLastFocusedEl(focus);
            }
        },
        [prev, selectedIndex, swiperRef, swiperTransitionActive]
    );

    // display first slide directly on mount
    useEffect(() => {
        swiperRef?.slideTo(initialIndex, 0);
    }, [initialIndex, swiperRef]);

    // gamepad shortcut with right/left triggers to go previous/next game
    // this is implemented on the swiper, not on the windows or it would be triggered for every window instance
    useTriggerBottomLeftPress(
        useCallback(() => {
            if (currentGameWindowView === 'description') {
                onPrev('');
            }
        }, [currentGameWindowView, onPrev])
    );

    useTriggerBottomRightPress(
        useCallback(() => {
            if (currentGameWindowView === 'description') {
                onNext('');
            }
        }, [currentGameWindowView, onNext])
    );

    const setGameWindowFocus = useCallback(() => {
        // swiperRef exists ? + current view must be set to description
        if (
            !swiperRef ||
            currentGameWindowView !== 'description' ||
            swiperTransitionActive
        )
            return;

        //if user navigate quickly and close while transitionning, swiperRef.destroyed can be true
        if (swiperRef.destroyed) return;

        activeSlide.current = swiperRef.$wrapperEl[0].querySelector(
            '.swiper-slide.swiper-slide-active'
        );

        if (!activeSlide.current) return;

        const focusButton = (buttonId) => {
            let button = activeSlide.current.querySelector(
                `[data-btn="${buttonId}"]`
            );
            if (!button) {
                //fallback to play if requested button not found
                if (process.env.NODE_ENV === 'development') {
                    console.error(
                        'Button',
                        buttonId,
                        'not found in',
                        currentGameWindowView,
                        'view'
                    );
                }
                button = activeSlide.current.querySelector(
                    `[data-btn="${descriptionActionBtn.PLAY}"]`
                );
            }
            if (button) {
                setFocus(button);
            }
            button?.focus();
        };

        // play > trailer > more, depending on availability
        const defaultButton =
            games[selectedIndex].release_status === 'coming_soon'
                ? games[selectedIndex].assets.trailer
                    ? descriptionActionBtn.TRAILER
                    : descriptionActionBtn.MORE
                : descriptionActionBtn.PLAY;

        switch (lastFocusedEl) {
            case descriptionNavBtn.PREV:
            case descriptionNavBtn.NEXT:
            case descriptionActionBtn.MORE:
            case descriptionActionBtn.QUICKMATCH:
            case descriptionActionBtn.MULTIPLAYER:
                focusButton(lastFocusedEl);
                break;
            default:
                focusButton(defaultButton);
                break;
        }
    }, [
        currentGameWindowView,
        games,
        lastFocusedEl,
        selectedIndex,
        setFocus,
        swiperRef,
        swiperTransitionActive,
    ]);

    useEffect(() => {
        setGameWindowFocus();
    }, [currentGameWindowView, setGameWindowFocus]);

    const onSlideChangeTransitionStart = () => {
        setSwiperTransitionActive(true);
    };

    const onSlideChangeTransitionEnd = useCallback(
        (swiper) => {
            // for some reasons, if we are trying to get active slide with this method right after onSlideChangeTransitionEnd, active slide is undefined
            setTimeout(() => {
                setSwiperTransitionActive(false);
                setGameWindowFocus();
            }, 10);
        },
        [setGameWindowFocus]
    );

    return (
        <S.Wrapper zIndex={zIndex}>
            <Swiper
                style={{ width: '100%', height: '100%' }}
                virtual
                allowTouchMove={isMobileOrTablet ? true : false}
                onSwiper={setSwiperRef}
                slidesPerView={1}
                speed={isTV ? 0 : 300} // no animation on TV
                centeredSlides={true}
                spaceBetween={0}
                preloadImages={false}
                simulateTouch={false} // if true, Swiper will accept mouse events like touch events (click and drag to change slides)
                resistance={false} // Set to false if you want to disable resistant bounds
                onSlideChangeTransitionStart={onSlideChangeTransitionStart}
                onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            >
                {games.map((game, index) => (
                    <SwiperSlide key={game.alias} virtualIndex={index}>
                        <S.SwiperSlideWrapper>
                            <GameWindow
                                index={index}
                                selectedIndex={selectedIndex}
                                indexMax={games.length - 1}
                                setCurrentGameWindowView={
                                    setCurrentGameWindowView
                                }
                                defaultView={gamesSwiper.defaultView.current}
                                game={games[index]}
                                onClose={gamesSwiper.close}
                                onNext={onNext}
                                onPrev={onPrev}
                                lastFocusedEl={lastFocusedEl}
                                setLastFocusedEl={setLastFocusedEl}
                            />
                        </S.SwiperSlideWrapper>
                    </SwiperSlide>
                ))}
            </Swiper>
        </S.Wrapper>
    );
};
