import { isMobile } from 'app/device';
import { GameItem } from 'components/games';
import { RowLayout } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { LeftPan, RightPan } from './View.style';

export const ViewContainer = ({ game, actionButtons, children }) => {
    return (
        <SpatialNavSection enterTo="default-element">
            <RowLayout fullSize>
                <LeftPan>
                    <GameItem game={game} src={game.assets.cover} />
                    <If condition={isMobile && actionButtons}>
                        {actionButtons}
                    </If>
                </LeftPan>
                <RightPan>{children}</RightPan>
            </RowLayout>
        </SpatialNavSection>
    );
};
