import { IconArrowLeft, IconArrowRight, NavButtonBase } from 'components/ui';
import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { useSpatialNavContext } from 'context';
import If from 'helpers';
import { useSwiper } from 'hooks';
import { createRef, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { OffersSwiperSlide } from './offers-swiper-slide/OffersSwiperSlide';
import * as S from './OffersSwiper.style';

export function OffersSwiper({ offers, setOfferSelected, isVisible }) {
    const {
        prevSlide,
        nextSlide,
        prevRef,
        nextRef,
        onSlideChangeTransitionEnd,
        setSwiperRef,
        activeIndex,
    } = useSwiper(offers.length);

    const { setFocus } = useSpatialNavContext();

    const [ActionButtonsRefs] = useState(() => offers.map(() => createRef()));

    // force focus on buttons when index changed and no focus, or focus was on one of the other buttons
    useEffect(() => {
        const focusOnAnotherButton = ActionButtonsRefs.some(
            (r, i) =>
                i !== activeIndex &&
                // action buttons refs are the buttons containers, we are focusing a button
                // if the parent of the active element is one of those refs
                document.activeElement?.parentNode === r.current
        );
        if (document.activeElement !== document.body && !focusOnAnotherButton) {
            return;
        }
        setFocus(ActionButtonsRefs[activeIndex].current);
    }, [ActionButtonsRefs, activeIndex, setFocus]);

    // use swiper if more than one offer, otherwise just put the slide directly
    const single = offers.length === 1;
    const SwiperComponent = single ? S.SingleSlideWrapper : AppSwiper;

    return (
        <S.Wrapper isVisible={isVisible}>
            <If condition={!single}>
                <S.NavButtonLeftWrapper>
                    <NavButtonBase
                        onClick={prevSlide}
                        isDisabled={activeIndex === 0}
                    >
                        <IconArrowLeft />
                    </NavButtonBase>
                </S.NavButtonLeftWrapper>
            </If>
            <SwiperComponent
                swiperStyle={{ width: '100%', height: '100%' }}
                setSwiperRef={setSwiperRef}
                slidesPerView={1.5}
                spaceBetween={100}
                centeredSlides={true}
                prevRef={prevRef}
                nextRef={nextRef}
                onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            >
                {offers.map((offer, index) => {
                    return (
                        // for some reasons, SwiperSlide has to be in the same component as Swiper
                        <SwiperSlide key={offer.alias} virtualIndex={index}>
                            <OffersSwiperSlide
                                ref={ActionButtonsRefs[index]}
                                prevSlide={prevSlide}
                                nextSlide={nextSlide}
                                defaultElement={activeIndex === index}
                                offer={offer}
                                setOfferSelected={setOfferSelected}
                                index={index}
                            />
                        </SwiperSlide>
                    );
                })}
            </SwiperComponent>
            <If condition={!single}>
                <S.NavButtonRightWrapper>
                    <NavButtonBase
                        onClick={nextSlide}
                        isDisabled={activeIndex === offers.length - 1}
                    >
                        <IconArrowRight />
                    </NavButtonBase>
                </S.NavButtonRightWrapper>
            </If>
        </S.Wrapper>
    );
}
