import { useState } from 'react';
import { useCharts, useGamesSwiper } from 'hooks';
import { GamesWrapper, Grid } from 'components/games';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { Titles, TitlesWrapper } from 'components/games/charts';
import { ColumnLayout } from 'components/ui';
import { QuitWrapper, Scrollable } from 'components';
import { If } from 'helpers';
import { isMobile } from 'app/device';

export function ChartsScreen() {
    const { colsTitles, columns } = useCharts();
    const [games, setGames] = useState([]);
    const gamesSwiper = useGamesSwiper(games);

    const onColumnClicked = (colIndex) => setGames(columns[colIndex]);

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper minTop={isMobile ? 250 : 350}>
                    <ColumnLayout fullWidth>
                        <Scrollable
                            isGapOpen={gamesSwiper.gapOpen}
                            lastFocused={gamesSwiper.lastFocused}
                        >
                            <TitlesWrapper>
                                <Titles colsTitles={colsTitles} />
                            </TitlesWrapper>
                        </Scrollable>
                        <Grid
                            isGapOpen={gamesSwiper.gapOpen}
                            lastFocused={gamesSwiper.lastFocused}
                            onColumnClicked={onColumnClicked}
                            onItemClicked={gamesSwiper.open}
                            columns={columns}
                        />
                    </ColumnLayout>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
