import styled from 'styled-components';

import { RowLayout as RowLayoutBase } from 'components/ui';

export const RightPan = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
`;

export const RowLayout = styled(RowLayoutBase)`
    > *:not(:last-child) {
        margin-right: 1rem;
    }
`;
