import { isHP } from 'app/device';
import { withInputDispatcherProvider } from 'context';
import { MultiView, View } from 'context/multi-view';
import { useActionTopPress, useHomePress } from 'hooks';
import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import { useCallback } from 'react';
import { Games } from 'services';
import * as S from './GameWindow.style';
import { DescriptionView } from './views/description/DescriptionView';
import { MoreView } from './views/more/MoreView';
import { MultiChoiceView } from './views/multi/MultiChoiceView';
import { QuickMatchView } from './views/quickmatch/QuickMatchView';

export const GameWindow = withInputDispatcherProvider(
    ({ defaultView, ...props }) => {
        const favorite = useToggleFavorite();
        const { index, onClose, selectedIndex } = props;

        useHomePress(
            useCallback(() => {
                if (isHP) {
                    onClose();
                }
            }, [onClose])
        );

        // default view may be quickmatch, use only for the current window
        const view =
            index === selectedIndex && defaultView
                ? defaultView
                : 'description';

        useActionTopPress(() => {
            if (!Games.FavoritesEnabled()) {
                return;
            }
            favorite.toggleGame(props.game.alias);
        });

        return (
            <S.Wrapper>
                <S.Window>
                    <MultiView defaultView={view}>
                        <View
                            name="description"
                            component={DescriptionView}
                            {...props}
                        />
                        <View
                            name="multi"
                            component={MultiChoiceView}
                            {...props}
                        />
                        <View
                            name="quick-match"
                            component={QuickMatchView}
                            {...props}
                        />
                        <View
                            name="more-view"
                            component={MoreView}
                            {...props}
                        />
                    </MultiView>
                </S.Window>
            </S.Wrapper>
        );
    }
);
