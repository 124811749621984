import { useFocusFollow } from 'hooks';
import * as S from './GamesWrapper.style';

export function GamesWrapper({
    children,
    isGapOpen,
    isDiscoveryOffer,
    ...props
}) {
    const { ref, onFocus } = useFocusFollow(props);

    return (
        <S.GamesWrapper
            ref={ref}
            onFocus={onFocus}
            isDiscoveryOffer={isDiscoveryOffer}
        >
            {children}
        </S.GamesWrapper>
    );
}

export { LeadingGame, NoGamesFound } from './GamesWrapper.style';
