import { createSlice } from '@reduxjs/toolkit';
import { BASE_NAVIGATION } from 'app/navigation';
import { createSelector } from 'reselect';

const BASE_STATE = {
    currentTab: {
        name: '',
        subTab: '',
    },
    currentAsideTab: {
        name: '',
    },
    navigation: {
        ...BASE_NAVIGATION,
    },
    menuVisible: false,
    gameWindowOpen: false,
};

// Slice
const globalNavigationSlice = createSlice({
    name: 'globalNavigation',

    initialState: BASE_STATE,

    reducers: {
        setCurrentTab: (state, { payload }) => {
            if (state.currentTab.name !== payload) {
                state.currentTab.name = payload;
            }
        },

        setCurrentSubTab: (state, action) => {
            state.currentTab.subTab = action.payload;
        },

        setCurrentAsideTab: (state, action) => {
            state.currentAsideTab.name = action.payload;
        },

        setGlobalNavigation: (state, action) => {
            state.navigation = action.payload;
        },

        showGameWindow: (state) => {
            state.gameWindowOpen = true;
        },

        hideGameWindow: (state) => {
            state.gameWindowOpen = false;
        },

        showGlobalNavigation: (state) => {
            state.menuVisible = true;
        },

        hideGlobalNavigation: (state) => {
            state.menuVisible = false;
        },

        resetCurrentTab: (state) => {
            state.currentTab = {
                name: '',
                subTab: '',
            };
        },

        resetCurrentAsideTab: (state) => {
            state.currentAsideTab = {
                name: '',
            };
        },

        resetGlobalNavigation: (state) => BASE_STATE,
    },
});

// Actions
export const {
    setCurrentTab,
    setCurrentSubTab,
    setCurrentAsideTab,
    hideGlobalNavigation,
    showGlobalNavigation,
    resetCurrentTab,
    setSubMenuVisible,
    resetCurrentAsideTab,
    resetGlobalNavigation,
    showGameWindow,
    hideGameWindow,
    setGlobalNavigation,
} = globalNavigationSlice.actions;

// Selector
export const globalNavigationSelector = (state) => state.globalNavigation;

// Memoized Selector see : https://react-redux.js.org/api/hooks#using-memoizing-selectors
export const selectCurrentSubMenuItems = createSelector(
    (state) => state.globalNavigation,
    (globalNavigation) =>
        globalNavigation.navigation.main.find(
            (item) => item.name === globalNavigation.currentTab.name
        )?.subMenu
);

// Reducer
export const globalNavigationReducer = globalNavigationSlice.reducer;
