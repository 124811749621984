import styled, { css } from 'styled-components';
import Theme from 'app/theme';
import { mobile, supportBackDropFilter } from 'app/device';
import { BlurBox } from '../../blur-box';

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${(props) => (props.zIndex ? props.zIndex : 999)};
`;

const BlurredWallpaperBase = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: ${() =>
        `blur(${Theme.BLUR_AMOUNT}px)
        brightness(${Theme.BLUR_BRIGHTNESS}%)
        contrast(${Theme.BLUR_CONTRAST}%)
        saturate(${Theme.BLUR_SATURATE}%)
    `};
`;

const BlurredWallpaperTV = styled(BlurredWallpaperBase).attrs((props) => ({
    style: {
        backgroundImage: `url(${props.theme.wallpaper})`,
        ...props.style,
    },
}))`
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
`;
export const BlurredWallpaper = !supportBackDropFilter
    ? BlurredWallpaperTV
    : BlurredWallpaperBase;

// Message box
const MessageBoxBase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem;
    font-size: 1.3rem;
    backdrop-filter: ${() =>
        `blur(${Theme.BLUR_AMOUNT * 1.5}px) brightness(140%)`};
    box-sizing: border-box;
    min-width: 650px;
    max-width: 960px;

    ${mobile(css`
        max-width: 90vw;
        max-height: 96vh;
        padding: 1.5rem;
    `)}
`;

const MessageBoxTV = styled(BlurBox)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    padding: 2rem;
    font-size: 1.3rem;
    max-width: 50vw;
`;

export const MessageBox = !supportBackDropFilter
    ? MessageBoxTV
    : MessageBoxBase;
