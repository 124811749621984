import { useEffect, useRef } from 'react';
import { isTV } from '../../app/device';
import { useBackPress } from '../input-dispatch';

export function useTrailer(closeWithSound, closeWithoutSound) {
    const videoRef = useRef();
    const sourceRef = useRef();

    useBackPress(closeWithSound);

    useEffect(() => {
        // Disable controls on TV
        if (isTV) videoRef.current.controls = false;

        // Video end
        videoRef.current.addEventListener('ended', () => {
            closeWithoutSound();
        });

        // Source error
        sourceRef.current.addEventListener('error', (err) => {
            console.error(err);
            closeWithoutSound();
        });
    }, [closeWithoutSound]);

    return { videoRef, sourceRef };
}
