import styled from 'styled-components';
import Theme from 'app/theme';

export const RegisterUrl = styled.a`
    text-decoration: underline;
    color: ${Theme.COLORS.BLUE_L3};
    cursor: pointer;
    margin-bottom: 1.5rem;
`;

export const QrCode = styled.div`
    margin-top: 1rem;
    border: 1px solid red;
`;
