import { Profile } from 'components/profile/selection-manager/profile/Profile';
import { SpatialNavSection } from 'context';
import { useSoundAction, useMultiItemsPerViewSwiper } from 'hooks';
import 'swiper/swiper.scss';
import * as S from './ProfileListSwiper.style';

export function ProfileListPlain({ list, listItemSelected }) {
    const { focusActiveItem, nextSlide, prevSlide, slideRefs } =
        useMultiItemsPerViewSwiper(list.length);

    const handleSelected = useSoundAction('primaryBtn', listItemSelected);

    return (
        <SpatialNavSection focusOnMount enterTo="last-focused">
            <S.Wrapper>
                {list.map((profile, index) => (
                    <Profile
                        key={profile.uid}
                        ref={slideRefs[index]}
                        data={profile}
                        count={3}
                        onClick={() => handleSelected(profile)}
                        overrideMoveLeft={prevSlide}
                        overrideMoveRight={nextSlide}
                        onFocus={focusActiveItem}
                    />
                ))}
            </S.Wrapper>
        </SpatialNavSection>
    );
}
