import { BlurBox as BlurBoxBase } from 'components/ui';

import { DailyScheduleWrapper } from './daily-schedule/DailySchedule.style';
import styled, { css } from 'styled-components';
import { mobile, tablet } from 'app/device';

export const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    width: 100%;
    box-sizing: border-box;

    > ${DailyScheduleWrapper}:last-of-type {
        margin-bottom: 1.5rem;
    }

    ${mobile(css`
        font-size: 1rem;
    `)}
`;

export const ContentWrapperSpacing = styled(ContentWrapper)`
    padding: 1.5rem;

    ${mobile(css`
        padding: 0.5rem 10px 0.5rem;
    `)}
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
`;

export const BlurBox = styled(BlurBoxBase)`
    width: 96%;
    max-width: 1350px;
    overflow: hidden;
    filter: ${(props) => (props.overlay ? 'blur(2px)' : 'blur(0px)')};

    ${tablet(css`
        width: 98%;
        max-width: 85vw;
    `)}

    ${mobile(css`
        width: 98%;
        max-width: 100%;
    `)}
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
`;
