import { GlobalStyle } from 'components';
import { ErrorBoundaryWrapper } from 'components/error-boundary/ErrorBoundary';
import {
    InputDispatcherProvider,
    SharedElementProvider,
    SpatialNavProvider,
    ThemeProvider,
} from 'context';
import { useGlobalSound } from 'hooks';

export function AppProviders(props) {
    useGlobalSound();

    return (
        <ThemeProvider>
            <GlobalStyle />
            <ErrorBoundaryWrapper>
                <InputDispatcherProvider>
                    <SharedElementProvider renderTo="root">
                        <SpatialNavProvider debugDraw={false} debug={false}>
                            {props.children}
                        </SpatialNavProvider>
                    </SharedElementProvider>
                </InputDispatcherProvider>
            </ErrorBoundaryWrapper>
        </ThemeProvider>
    );
}
