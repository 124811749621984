import styled, { css } from 'styled-components';
import { Avatar } from 'components/ui';
import { tv, mobile, mobileSmall, hover } from 'app/device';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';

export const SwiperSlideWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    outline: none;
    user-select: none;

    ${mobile(css`
        margin: 0 0;
    `)};

    ${tv(css`
        margin: 0 40px;
    `)}
`;

export const ProfileAvatar = withFocusable(styled(Avatar)`
    width: 144px;
    height: 144px;
    position: relative;

    ${mobile(css`
        margin: ${(props) => (props.count <= 3 ? '0 20px' : ' 0 0')};
        height: 120px;
        width: 120px;
    `)};

    ${mobileSmall(css`
        margin: ${(props) => (props.count <= 2 ? '0 20px' : ' 0 0')};
        width: 90px;
        height: 90px;
    `)};

    ${tv(css`
        width: 244px !important;
        height: 244px !important;
    `)}

    &:focus {
        border-color: ${Theme.COLORS.PRIMARY_L2};
    }

    ${hover(css`
        &:hover {
            border-color: ${Theme.COLORS.PRIMARY_L2};
        }
    `)}
    &:active {
        border-color: ${Theme.COLORS.PRIMARY_L3};
    }
`);

export const ProfileAvatarWrapper = styled.div`
    position: relative;

    svg {
        width: 20px;
    }
`;

export const IconLockedWrapper = styled.div`
    background-color: #000;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Tag = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 4px 14px;
    margin-top: 8px;
    text-align: center;
    max-width: 110%;

    ${mobileSmall(css`
        padding: 2px 6px;
    `)};

    ${tv(css`
        margin-top: 18px;
        border-radius: 30px;
        padding: 14px 22px;
    `)}
`;

export const Text = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 144px;

    ${mobile(css`
        max-width: 85px;
    `)};

    ${mobileSmall(css`
        max-width: 50px;
    `)};

    ${tv(css`
        max-width: 244px !important;
    `)}
`;

export const Icon = styled.div`
    margin-left: 6px;
    width: 15px;
    ${tv(css`
        margin-left: 14px;
        width: 26px;
        height: 35px;
    `)}
`;
