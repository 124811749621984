// API base url

// APP_BACKEND_URL is defined in the static public/vars.js file so it can be injected
// at deployment time according to each customer, rather than defined at build time
// this can be overridden a dev time using the .env file
export let backendUrl;
try {
    // eslint-disable-next-line no-undef
    backendUrl = APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL;
} catch (e) {
    // nothing, try-catch is there for jest context
}
const API_BASE_URL = `${backendUrl}/api`;

const ClientOptions = {
    baseURL: API_BASE_URL,
    timeout: 10000,
    ResponseType: 'json',
    validateStatus: null,
};

export default ClientOptions;
