import { SharedElement } from 'components';
import {
    ErrorWrapper,
    RecoveryBoxWrapper,
    TitleWrapper,
} from 'components/account-recovery';
import * as S from 'components/account-recovery/RecoveryStep.style';
import { ActionButtons, BlurBox, Input, Logo } from 'components/ui';
import { useResetPassword } from 'hooks/recovery';
import { useTranslation } from 'react-i18next';

export function StepResetPassword() {
    const { t } = useTranslation();
    const { onSubmit, register, errors, passwordRef, cancelStep } =
        useResetPassword();

    return (
        <SharedElement id="recovery-box">
            <BlurBox>
                <RecoveryBoxWrapper>
                    <SharedElement id="recovery-logo" zIndex={2} scale>
                        <Logo />
                    </SharedElement>
                    <TitleWrapper
                        error={errors}
                        title={t('recovery.step_reset_password')}
                    />
                    <S.ContentWrapper>
                        <ErrorWrapper error={errors} />
                        <S.InputsWrapper>
                            <Input
                                focusOnMount
                                name="password"
                                type="password"
                                ref={register({ required: true })}
                                placeholder={t('recovery.password_placeholder')}
                                tabIndex={1}
                            />
                            <S.InputPasswordConfirm
                                name="password_repeat"
                                type="password"
                                maxLength="50"
                                placeholder={t(
                                    'recovery.password_confirmation_placeholder'
                                )}
                                tabIndex={2}
                                ref={register({
                                    validate: (value) =>
                                        value === passwordRef.current ||
                                        'recovery.step_password_reset_match_error',
                                    maxLength: 50,
                                })}
                            />
                        </S.InputsWrapper>
                    </S.ContentWrapper>
                    <ActionButtons
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.ok'),
                                callback: onSubmit,
                            },
                            {
                                type: 'regular',
                                text: t('buttons.cancel'),
                                callback: cancelStep,
                            },
                        ]}
                    />
                </RecoveryBoxWrapper>
            </BlurBox>
        </SharedElement>
    );
}
