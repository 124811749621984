import history from 'app/history';
import Routes from 'app/routes';
import { useSpatialNavContext } from 'context';
import {
    useAudioVideoSettings,
    useLanguageSelect,
    useLogout,
    useQuit,
    useSupportMessageBox,
} from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    globalNavigationSelector,
    resetCurrentTab,
    resetGlobalNavigation,
    setCurrentAsideTab,
    resetCurrentAsideTab,
} from 'slices';

export function useAsideNav() {
    const { currentTab, currentAsideTab, navigation } = useSelector(
        globalNavigationSelector
    );
    const dispatch = useDispatch();
    const logout = useLogout();
    const quit = useQuit();
    const language = useLanguageSelect();
    const support = useSupportMessageBox();
    const audioVideoSettings = useAudioVideoSettings();
    const { resetLastFocused } = useSpatialNavContext();

    const asideNavCursorRef = useRef();
    const settingsBoxRef = useRef();
    const settingsButtonRef = useRef();
    const settingsFirstFocus = useRef(true);
    const wrapperRef = useRef();

    const favoritesVisible = currentAsideTab.name === 'favorites';
    const isEmpty = useMemo(
        () => Object.keys(navigation.aside).length === 0,
        [navigation]
    );

    const [settingsVisible, setSettingsVisible] = useState(false);

    useEffect(() => {
        if (currentAsideTab.name === 'settings') {
            setSettingsVisible(true);
        } else {
            setSettingsVisible(false);
        }
    }, [currentAsideTab]);

    const hideCursor = useCallback(() => {
        asideNavCursorRef.current.style.opacity = 0;
    }, [asideNavCursorRef]);

    // check if there is one checkbox visible, if so we don't want to handle onDocumentClick (to close aside settings)
    const messageboxVisible = useMemo(() => {
        const messageboxes = [
            audioVideoSettings.visible,
            support.visible,
            language.visible,
            quit.visible,
            logout.visible,
        ];
        return messageboxes.some((messagebox) => messagebox === true);
    }, [
        audioVideoSettings.visible,
        support.visible,
        language.visible,
        quit.visible,
        logout.visible,
    ]);

    const onDocumentClick = useCallback(
        (e) => {
            if (!settingsVisible || messageboxVisible) {
                return;
            }

            // check if event target different from settingsBox and close settingsBox
            if (
                !settingsBoxRef.current?.contains(e.target) &&
                !settingsButtonRef.current.contains(e.target)
            ) {
                setSettingsVisible(false);
                hideCursor();
                dispatch(resetCurrentAsideTab());
            }
        },
        [dispatch, hideCursor, settingsVisible, messageboxVisible]
    );

    const onScroll = useCallback(
        (event) => {
            // prevent scroll when settings menu opened
            if (settingsVisible) {
                event.preventDefault();
            }
        },
        [settingsVisible]
    );

    useEffect(() => {
        // Event to trigger close settings on blur (click outside) and prevent scroll when settings menu opened
        document.addEventListener('click', onDocumentClick);
        window.addEventListener('wheel', onScroll, { passive: false });
        window.addEventListener('touchmove', onScroll, { passive: false });

        return () => {
            document.removeEventListener('click', onDocumentClick);
            window.removeEventListener('wheel', onScroll, { passive: false });
            window.removeEventListener('touchmove', onScroll, {
                passive: false,
            });
        };
    }, [onDocumentClick, onScroll]);

    const overrideMoveDown = () => {
        semiHighlightItem();
        return true;
    };

    const moveSelector = (index) => {
        asideNavCursorRef.current.style.opacity = 1;
        asideNavCursorRef.current.style.transform = `translate3d(${
            100 * index
        }%,0,0)`;
        asideNavCursorRef.current.style.boxShadow =
            '1px 1px 30px -1px rgba(0, 0, 0, 0.35)';
    };

    const semiHighlightItem = () => {
        asideNavCursorRef.current.style.backgroundColor =
            'rgba(255, 255, 255, 0.075)';
        asideNavCursorRef.current.style.boxShadow = '';
    };

    const onSettingsFocus = useCallback(() => {
        settingsFirstFocus.current = true;
    }, []);

    // hide cursor when element from asideNavigation selected
    useEffect(() => {
        if (currentTab.name !== '') hideCursor();
    }, [currentTab, hideCursor]);

    const onAsideNavItemSelected = useCallback(
        (item, index) => {
            dispatch(setCurrentAsideTab(item));
            moveSelector(index);

            switch (item) {
                case 'search':
                    history.push(Routes.GAMES.SEARCH);
                    dispatch(resetCurrentTab());
                    break;
                case 'favorites':
                    history.push(Routes.GAMES.FAVORITES);
                    dispatch(resetCurrentTab());
                    break;
                case 'settings':
                    //We don't want to dispatch resetCurrentTab when settings focus because the screen is still visible when settings visible, settings is not a ROUTE !
                    settingsFirstFocus.current = true;
                    break;
                default:
                    break;
            }
        },
        [dispatch]
    );

    const doLogout = () => {
        logout.handleLogout(() => {
            // hide the nav bar
            dispatch(resetGlobalNavigation());
            // reset last focus in nav bar - only once hidden
            resetLastFocused();
        });
    };

    return {
        favoritesVisible,
        settingsVisible,
        onSettingsFocus,
        logout,
        quit,
        support,
        language,
        audioVideoSettings,
        doLogout,
        asideNavCursorRef,
        wrapperRef,
        onAsideNavItemSelected,
        overrideMoveDown,
        navigation,
        currentAsideTab,
        isEmpty,
        settingsBoxRef,
        settingsButtonRef,
    };
}
