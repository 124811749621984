import styled from 'styled-components';
import { withFocusable } from 'helpers';

export const SwiperStyle = {
    width: '100%',
    height: '100%',
    margin: '0 25px',
    padding: '5px 10px',
};

export const SwiperSlide = {
    minWidth: '120px',
};

export const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 650px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Text = styled.div`
    font-size: 1rem;
    text-align: center;
`;

export const SwiperWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 25px 0;
    width: 100%;
`;

export const SwiperSlideBase = withFocusable(styled.div`
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center center;
    background-size: cover;
    border-radius: 25px;
    width: 150px;
    height: 100%;
    box-sizing: border-box;
    transition: border 200ms ease-out;
    transition: box-shadow 200ms ease-out;

    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }

    &:focus {
        outline: none;
        box-shadow: inset 0px 0px 0px 4px #ffffff;
    }

    &:not(:last-of-type) {
        margin-right: ${(props) => (props.isPlain ? '2rem' : 0)};
    }
`);

export const SwiperSlideWrapper = styled(SwiperSlideBase).attrs((props) => ({
    style: {
        backgroundImage: `url(${props.srcImage})`,
        ...props.style,
    },
}))``;

export const NewGame = styled(SwiperSlideWrapper)``;

export const NewGameNoThumbnail = styled(SwiperSlideBase)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.5);

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        font-size: 0.65rem;
    }
`;

export const NewGamesWrapper = styled(SwiperWrapper)`
    justify-content: space-evenly;
`;
