import { If } from 'helpers';
import React from 'react';
import * as S from './MessageBoxTitle.style';

export function MessageBoxTitle({ title, Icon, title2, alignItems }) {
    return (
        <S.BoxTitleWrapper style={{ alignItems: alignItems || 'flex-start' }}>
            <S.Wrapper>
                <If condition={Icon}>
                    <S.IconTitle>
                        <Icon />
                    </S.IconTitle>
                </If>
                <If condition={title}>
                    <S.BoxTitle>{title}</S.BoxTitle>
                </If>
            </S.Wrapper>

            <If condition={title2}>
                <S.BoxTitle2>{title2}</S.BoxTitle2>
            </If>
        </S.BoxTitleWrapper>
    );
}
