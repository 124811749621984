import { DangerButton, NeutralButton } from 'components/ui/buttons';
import { IconWarning } from 'components/ui/index';
import { MessageBoxWrapper } from 'components/ui/message-box';
import * as S from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ActionButtonsWrapper } from '../ActionButtonsWrapper';

export const ConfirmationBox = withInputDispatcherProvider(
    ({
        children,
        message,
        message2,
        onAccept,
        onDecline,
        accept,
        decline,
        AcceptButtonComponent = DangerButton,
    }) => {
        const { t } = useTranslation();
        useBackPress(onDecline);

        const Wrapper =
            children || message2 ? S.MultipleMessagesWrapper : S.MessageWrapper;

        // Note: nested backdrop-filters does not work
        return createPortal(
            <MessageBoxWrapper>
                <Wrapper>
                    <S.Icon>
                        <IconWarning />
                    </S.Icon>
                    <If condition={message}>
                        <S.Text>{message}</S.Text>
                    </If>
                    <If condition={message2}>
                        <S.Text>{message2}</S.Text>
                    </If>
                    {children}
                </Wrapper>
                <ActionButtonsWrapper>
                    <AcceptButtonComponent
                        style={{ marginRight: '1rem' }}
                        onClick={onAccept}
                        bold
                    >
                        {accept || t('buttons.yes')}
                    </AcceptButtonComponent>
                    <NeutralButton onClick={onDecline} bold>
                        {decline || t('buttons.cancel')}
                    </NeutralButton>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>,
            document.body
        );
    }
);
ConfirmationBox.displayName = 'ConfirmationBox';
