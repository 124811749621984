import { SharedElement } from 'components';
import { MegaLoaderWrapper, MegaProgressBar } from 'components/mega-loader';
import { BlurBox, Logo, RowLayout } from 'components/ui';
import { useThemeContext } from 'context';
import { useMegaLoader } from 'hooks';
import { useEffect } from 'react';

export function MegaLoaderScreen() {
    const { progress, onProgressComplete, startLoading } = useMegaLoader();
    const { setStaticWallpaper } = useThemeContext();

    useEffect(() => {
        setStaticWallpaper('login');
    }, [setStaticWallpaper]);

    return (
        <RowLayout alignItems="center" justifyContent="center" fullSize>
            <SharedElement id="box">
                <BlurBox>
                    <MegaLoaderWrapper>
                        <SharedElement id="logo" zIndex={2} scale>
                            <Logo />
                        </SharedElement>
                        <MegaProgressBar
                            onVisible={startLoading}
                            progress={progress}
                            onProgressComplete={onProgressComplete}
                        />
                    </MegaLoaderWrapper>
                </BlurBox>
            </SharedElement>
        </RowLayout>
    );
}
