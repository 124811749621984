import { QrCodeBox } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from './content';
import { Header } from './header';
import * as S from './OffersSwiperSlide.style';

export const OffersSwiperSlide = forwardRef(
    (
        {
            offer,
            setOfferSelected,
            index,
            defaultElement,
            nextSlide,
            prevSlide,
            ...props
        },
        forwardedRef
    ) => {
        const [qrCodeVisible, setQrCodeVisible] = useState(false);
        const { t } = useTranslation();

        return (
            <>
                <If condition={qrCodeVisible}>
                    <QrCodeBox
                        title={t('login.register_link')}
                        message={t('login.subscribe')}
                        url={offer.assets?.buy_link}
                        onClose={() => {
                            setQrCodeVisible(false);
                        }}
                    />
                </If>
                <SpatialNavSection enterTo="default-element">
                    <S.SwiperSlideWrapper>
                        <S.Wrapper>
                            <Header offer={offer} />
                            <Content
                                prevSlide={prevSlide}
                                nextSlide={nextSlide}
                                offer={offer}
                                setQrCodeVisible={setQrCodeVisible}
                                setOfferSelected={setOfferSelected}
                                ref={forwardedRef}
                            />
                        </S.Wrapper>
                    </S.SwiperSlideWrapper>
                </SpatialNavSection>
            </>
        );
    }
);
