import { mobile } from 'app/device';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    justify-content: center;
    width: 100%;
    height: 95vh;
    right: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;

    ${mobile(css`
        height: 85vh;
    `)}
`;

export const SingleSlideWrapper = styled.div`
    flex-grow: 1;
    max-width: 62%;
`;

const NavButtonWrapperBase = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 888;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NavButtonLeftWrapper = styled(NavButtonWrapperBase)`
    left: 10vw;

    ${mobile(css`
        left: 8vw;
    `)}
`;

export const NavButtonRightWrapper = styled(NavButtonWrapperBase)`
    right: 10vw;

    ${mobile(css`
        right: 8vw;
    `)}
`;
