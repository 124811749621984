import { isHP } from 'app/device';
import Routes from 'app/routes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Games, Profile } from 'services';
import {
    globalNavigationSelector,
    setCurrentProfile,
    setCurrentTab,
    setFavorites,
    showGlobalNavigation,
    updateProfile,
} from 'slices';

export function useStartNavigation() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { navigation } = useSelector(globalNavigationSelector);

    useEffect(() => {
        if (navigation?.main?.length > 0) {
            // Start up with right tab
            dispatch(setCurrentTab(navigation.main[0].name));
        }
    }, [dispatch, navigation]);

    return useCallback(
        async (profileUID, pinCode) => {
            // get full profile information - favorites, remaining playtime, etc
            const fullProfile = await Profile.LoadFullProfile(
                profileUID,
                pinCode
            );
            // update profile slice and current profile with full info
            dispatch(updateProfile(fullProfile));
            dispatch(setCurrentProfile(fullProfile));
            // set favorites
            dispatch(setFavorites(fullProfile.favorites));
            // load stats
            Games.SetStats(profileUID);

            // Route to games
            dispatch(showGlobalNavigation());

            history.push(
                isHP ? Routes.GAMES.CATEGORIES : Routes.GAMES.HIGHLIGHTS
            );
        },
        [dispatch, history]
    );
}
