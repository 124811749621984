import styled, { css } from 'styled-components';
import { mobile, tablet } from 'app/device';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const LangWrapper = styled.div`
    position: absolute;
    right: 2rem;
    top: 2rem;
`;

export const LangOverlay = styled.div`
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.5);
    border-left: 200px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
`;

export const IconWrapper = styled.div`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

export const Title = styled.div`
    text-align: center;
    font-size: 2rem;
    margin: 4rem 0 4rem 0;
    white-space: pre-line;

    ${mobile(css`
        font-size: 1.25rem;
        margin: 1rem 0 1.5rem 0;
    `)}

    ${tablet(css`
        font-size: 1.25rem;
        margin: 2rem 0 2.5rem 0;
    `)}
`;

export const BottomWrapper = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${tablet(css`
        bottom: 0.5rem;
        justify-content: flex-end;

        > div {
            margin-right: 20px;
        }
    `)}
`;
